import { groupBy, map, orderBy } from 'lodash';
import { ChartsV2Query_chartsV2 as ChartsQueryChart } from 'src/apollo/definitions/ChartsV2Query';
import { SongAggregatedRanking } from 'src/apollo/selectors';
import { ALL } from 'src/constants';
import {
    FREQUENCY_DAILY,
    FREQUENCY_WEEKLY,
    TYPE_VIRAL,
} from 'src/constants/charts';
import {
    YOUTUBE_LINK,
    YOUTUBE_SLUG_LINK_CHANNEL,
    YOUTUBE_SLUG_LINK_WATCH_VIDEO,
} from 'src/constants/providers';
import {
    STORE_IDS_BY_NAME,
    STORE_SPOTIFY,
    STORE_TIKTOK,
} from 'src/constants/stores';

export interface RankingFilters {
    country?: string;
    chart?: string;
    onlyCurrentAppearances?: boolean;
}

export interface RankingFiltersV2 {
    country?: string;
    genre?: string;
    isCurrent?: boolean;
    store?: string;
    type?: string;
}

export enum YoutubeTypeLinks {
    channel = 'channel',
    video = 'video',
}

export interface IBuildYoutubeLink {
    type: YoutubeTypeLinks;
    videoId?: string;
    channel?: string;
}

export const filterRankings = <TRanking extends SongAggregatedRanking>(
    rankings: TRanking[],
    rankingFilters: RankingFilters
) => {
    const { country, chart, onlyCurrentAppearances } = rankingFilters;

    const byCountry = country
        ? rankings.filter(row => row.country === country)
        : rankings;

    const byChart = chart
        ? byCountry.filter(row => row.chart.definition.key === chart)
        : byCountry;

    return onlyCurrentAppearances
        ? byChart.filter(row => !!row.position)
        : byChart;
};

export const filterRankingsV2 = <TRanking extends SongAggregatedRanking>(
    rankings: TRanking[],
    rankingFilters: RankingFiltersV2
) => {
    const { country, genre, isCurrent, store, type } = rankingFilters;

    return rankings.filter(row => {
        const hasPosition = !!row.position;
        // Filter by store, bypass if store is ALL or undefined
        if (
            store &&
            store !== ALL &&
            row.chart.platform &&
            STORE_IDS_BY_NAME[row.chart.platform].toString() !== store
        ) {
            return false;
        }

        // Filter by country if specified
        if (country && row.country !== country) {
            return false;
        }

        // Filter by genre if specified
        if (genre && row.chart.definition.key !== genre) {
            return false;
        }

        // Apply custom filter for type if specified
        if (type && !filterByType(row, type, store)) {
            return false;
        }

        return isCurrent ? hasPosition : !hasPosition;
    });
};

export const filterByType = (
    chart: SongAggregatedRanking,
    type = '',
    store = ''
) => {
    if (store === STORE_SPOTIFY) {
        return type === TYPE_VIRAL
            ? chart.chart.type === type
            : chart.chart.frequency === type && chart.chart.type !== TYPE_VIRAL;
    }

    if (store === STORE_TIKTOK) {
        return type === ALL
            ? chart.chart.frequency === FREQUENCY_DAILY
            : chart.chart.frequency === type;
    }

    return chart.chart.frequency === type;
};

export const filterChartsData = (data: ChartsQueryChart[]) =>
    data.filter(chart => {
        if (!chart) return false;

        const { frequency, platform, type } = chart;
        const isSpotifyWeeklyViral =
            platform === STORE_SPOTIFY &&
            frequency === FREQUENCY_WEEKLY &&
            type === TYPE_VIRAL;

        return !isSpotifyWeeklyViral;
    });

export const getChartsFromRankings = (
    rankings: SongAggregatedRanking[],
    rankingFilters?: RankingFilters
) =>
    orderBy(
        map(
            groupBy(
                rankings,
                ({
                    chart: {
                        definition: { key },
                    },
                }) => key
            ),
            group => {
                const [first] = group;
                const {
                    chart: { definition },
                } = first;
                let countFilteredCountriesForChart: number | undefined;
                if (rankingFilters?.country)
                    countFilteredCountriesForChart =
                        rankingFilters.onlyCurrentAppearances
                            ? group.filter(
                                  row =>
                                      row.country === rankingFilters.country &&
                                      row.position
                              ).length
                            : group.filter(
                                  row => row.country === rankingFilters.country
                              ).length;
                else
                    countFilteredCountriesForChart =
                        rankingFilters?.onlyCurrentAppearances
                            ? group.filter(row => row.position).length
                            : group.length;

                return {
                    ...definition,
                    data: {
                        countFilteredCountriesForChart,
                    },
                    value: definition.key,
                    count: group.length,
                };
            }
        ).filter(c => c.data.countFilteredCountriesForChart > 0),
        'label',
        'asc'
    );

export const buildYoutubeLink = ({
    type,
    videoId,
    channel,
}: IBuildYoutubeLink) => {
    switch (type) {
        case YoutubeTypeLinks.video:
            return `${YOUTUBE_LINK}${YOUTUBE_SLUG_LINK_WATCH_VIDEO}${videoId}`;
        case YoutubeTypeLinks.channel:
            return `${YOUTUBE_LINK}${YOUTUBE_SLUG_LINK_CHANNEL}${channel}`;

        default:
            return YOUTUBE_LINK;
    }
};
