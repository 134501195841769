import React, { FC } from 'react';
import {
    concatNames,
    SoundRecordingSearchResultItem,
} from 'src/apollo/selectors';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_SONG } from 'src/constants';
import RecentSearchedItem from 'src/pages/searchES/components/recentlyViewedItems/recentSearchedItem';
import { TERM_SONG } from 'src/pages/searchES/constants';
import { createHref } from 'src/utils/route';

const SongRecentSearched: FC<{ item: SoundRecordingSearchResultItem }> = ({
    item: { name, isrc, imageUrl, participants },
}) => {
    const areParticipantsAvailable = participants?.length !== 0;

    return (
        <RecentSearchedItem
            title={name}
            type={TERM_SONG}
            url={createHref(ROUTE_SONG, { isrc })}
            coverShape="square"
            coverWidth="30"
            imageUrl={imageUrl}
        >
            {areParticipantsAvailable && (
                <>
                    &nbsp;&#8209;&nbsp;
                    <TruncatedText value={concatNames(participants)} />
                </>
            )}
            {isrc && (
                <>
                    &nbsp;&#8209;&nbsp;
                    <span>{isrc}</span>
                </>
            )}
        </RecentSearchedItem>
    );
};

export default SongRecentSearched;
