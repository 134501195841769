import React, { FC } from 'react';
import { SkeletonLoader } from '@theorchard/suite-components';
import cx from 'classnames';
import { LoadingIndicatorCommonProps } from 'src/components/table/cells/loadingIndicators/loadingIndicators';

const CLASSNAME = 'countryLoadingIndicator';

const CountryLoadingIndicator: FC<LoadingIndicatorCommonProps> = ({
    className,
}) => (
    <div className={cx(CLASSNAME, className)}>
        <SkeletonLoader className="country" />
    </div>
);

export default CountryLoadingIndicator;
