import { get, difference, uniq, flatMap, uniqBy } from 'lodash';
import { CompanyBrands } from 'src/apollo/definitions/CompanyBrands';
import {
    GlobalProductQuery,
    GlobalProductQuery_globalProductByUpc_catalogProduct_labelParticipations as globalParticipations,
    GlobalProductQuery_globalProductByUpc_catalogProduct_labelParticipations_labelParticipant_globalParticipant as globalParticipant,
} from 'src/apollo/definitions/GlobalProductQuery';
import { GlobalProductSearch } from 'src/apollo/definitions/GlobalProductSearch';
import {
    GlobalProductTopTracksQuery,
    GlobalProductTopTracksQuery_globalProductByUpc_catalogProduct_tracks as TopTrack,
    GlobalProductTopTracksQuery_globalProductByUpc_catalogProduct_tracks_analytics_streams_growthPeriods as GrowthPeriod,
} from 'src/apollo/definitions/GlobalProductTopTracksQuery';
import { GlobalProductTracksQuery } from 'src/apollo/definitions/GlobalProductTracksQuery';
import {
    DeltaGrowthPeriod,
    TrackOfferType,
} from 'src/apollo/definitions/globalTypes';
import {
    MultiProductTracksQuery,
    MultiProductTracksQuery_topGlobalSoundRecordings as TopSoundRecordings,
} from 'src/apollo/definitions/MultiProductTracksQuery';
import { ProductAggregateStreamsQuery } from 'src/apollo/definitions/ProductAggregateStreamsQuery';
import { SongProductsQuery } from 'src/apollo/definitions/SongProductsQuery';
import {
    StarredGlobalProductsQuery,
    StarredGlobalProductsQuery_starredGlobalProducts_catalogProduct_labelParticipations as lParticipations,
    StarredGlobalProductsQuery_starredGlobalProducts_catalogProduct_labelParticipations_labelParticipant_globalParticipant as lParticipant,
} from 'src/apollo/definitions/StarredGlobalProductsQuery';
import { StarredGlobalProductsStreamsQuery } from 'src/apollo/definitions/StarredGlobalProductsStreamsQuery';
import { TopProductResultsQuery } from 'src/apollo/definitions/TopProductResultsQuery';
import { Participant } from 'src/apollo/selectors/participant';
import {
    selectAccounts,
    isSMEProduct,
    selectImageUrl,
} from 'src/apollo/selectors/utils';
import { filterData, nonNullable } from 'src/apollo/utils';
import { Account } from 'src/components/accountsDetailsPopup/types';
import { BRAND_KNR, EMPTY_CHAR, TERRITORY_RIGHTS } from 'src/constants';
import { getCompanyIcon } from 'src/utils/accounts';
import { getCountryCodesForRights } from 'src/utils/countries';

export interface Product {
    upc: string;
    labelName: string;
    name: string;
    releaseDate: string;
    saleStartDate: string;
    preorderDate?: string;
    imageUrl: string;
    participants: Participant[];
    following: boolean;
    isPartOfCatalog: boolean;
    format?: string;
    imprint: string;
    territoryRights?: string | null;
    territoriesWithRights?: (string | null)[];
    deleted: boolean;
    accounts?: Account[];
}

const assertGlobalParticipant = (
    data: globalParticipant | Partial<lParticipant & { imageUrl: 'undefined' }>
): data is globalParticipant => typeof data?.imageUrl !== 'undefined';

export const selectLabelParticipations = (
    data: (lParticipations | globalParticipations)[]
) =>
    filterData(
        data.map(p => {
            const participant = p.labelParticipant?.globalParticipant;

            if (!participant?.id || !participant?.name) return undefined;
            return {
                id: participant?.id,
                name: participant?.name,
                ...(assertGlobalParticipant(participant)
                    ? { imageUrl: participant?.imageUrl }
                    : {}),
                participatedAs: nonNullable(p.participated_as),
            } as Participant;
        })
    );

const selectTerritoryRights = (
    territoriesWithRights: (string | null)[],
    numberOfTerritories: number
): string => {
    if (!territoriesWithRights.length) return TERRITORY_RIGHTS.NONE;

    if (territoriesWithRights.length === numberOfTerritories)
        return TERRITORY_RIGHTS.FULL;

    return TERRITORY_RIGHTS.SPLIT;
};

export const selectProduct = ({
    globalProductByUpc,
}: GlobalProductQuery): Product | undefined => {
    if (!globalProductByUpc) return undefined;

    const { catalogProduct } = globalProductByUpc;
    const allCountryCodes = getCountryCodesForRights();

    const territoryCarveouts = catalogProduct.productTerritoryCarveouts.map(
        carveout => carveout.countryCode
    );
    const territoriesWithRights = difference(
        allCountryCodes,
        territoryCarveouts
    );

    const participants = selectLabelParticipations(
        catalogProduct.labelParticipations
    );

    return {
        upc: globalProductByUpc.upc,
        labelName: catalogProduct.labelName || EMPTY_CHAR,
        name: catalogProduct.productName || EMPTY_CHAR,
        releaseDate: catalogProduct.releaseDate || EMPTY_CHAR,
        saleStartDate: catalogProduct.saleStartDate || EMPTY_CHAR,
        preorderDate: catalogProduct.preorderDate || EMPTY_CHAR,
        imageUrl:
            selectImageUrl(
                globalProductByUpc.publicImageUrl,
                catalogProduct.imageLocation,
                isSMEProduct(catalogProduct.notForDistribution)
            ) || '',
        participants,
        imprint: catalogProduct.imprint || EMPTY_CHAR,
        following: globalProductByUpc.following,
        isPartOfCatalog: globalProductByUpc.isPartOfCatalog,
        format: nonNullable(catalogProduct.format) || EMPTY_CHAR,
        territoryRights: selectTerritoryRights(
            territoriesWithRights,
            allCountryCodes.length
        ),
        territoriesWithRights,
        deleted: catalogProduct.deletions === 'true',
        accounts: selectAccounts(
            [catalogProduct.label],
            catalogProduct.labelName
        ),
    };
};

export interface SongProduct {
    upc: string | null;
    productName: string | null;
    imageLocation: string | null;
    format: string | null;
    releaseDate: string | null;
    saleStartDate: string | null;
    labelName: string | null;
    deletions: string | null;
    offerType: string | null;
    territoryRights: string;
    territoriesWithRights: (string | null)[];
    accounts?: Account[];
}

export interface SongProductsResult {
    products: SongProduct[];
    territoryRights: string | null;
    territoriesWithRights: (string | null)[];
}

export const selectSongProducts = (
    data: SongProductsQuery | undefined
): SongProductsResult => {
    if (!data?.globalSoundRecordingByIsrc?.catalogProducts?.length)
        return {
            products: [],
            territoryRights: null,
            territoriesWithRights: [],
        };

    const allCountryCodes = getCountryCodesForRights();

    const products = filterData(
        data?.globalSoundRecordingByIsrc?.catalogProducts
    ).map(product => {
        const territoryCarveouts = product.productTerritoryCarveouts.map(
            carveout => carveout.countryCode
        );
        const territoriesWithRights = difference(
            allCountryCodes,
            territoryCarveouts
        );

        return {
            upc: product.upc,
            productName: product.deliveredVersion
                ? `${product.productName} (${product.deliveredVersion})`
                : product.productName,
            imageLocation:
                selectImageUrl(
                    product.globalProduct?.publicImageUrl,
                    product.imageLocation,
                    isSMEProduct(product.notForDistribution)
                ) || '',
            format: product.format,
            releaseDate: product.releaseDate,
            saleStartDate: product.saleStartDate,
            labelName: product.labelName,
            deletions: product.deletions,
            offerType: product.tracks?.[0]?.offerType ?? null,
            territoryRights: selectTerritoryRights(
                territoriesWithRights,
                allCountryCodes.length
            ),
            territoriesWithRights,
            accounts: selectAccounts([product.label], product.labelName),
        };
    });

    const nonDeletedProducts = products.filter(
        ({ deletions }) => deletions === 'false'
    );
    const territoriesWithRights = uniq(
        flatMap(nonDeletedProducts, product => product.territoriesWithRights)
    );

    return {
        products,
        territoryRights: selectTerritoryRights(
            territoriesWithRights,
            allCountryCodes.length
        ),
        territoriesWithRights,
    };
};

export interface ProductSearchResultItem {
    upc: string;
    productName: string;
    imageLocation: string;
    type: string;
    isPartOfCatalog: boolean;
    labelName: string;
    participants: Partial<Participant>[];
    releaseDate: string;
}

export interface ProductSearchResult {
    totalCount: number;
    items: ProductSearchResultItem[];
}

export const selectProductSearch = (
    data: GlobalProductSearch
): ProductSearchResult => {
    if (!data?.allProductsSearch?.products) return { items: [], totalCount: 0 };
    const items = filterData(data.allProductsSearch.products)
        .filter(({ contextType }) => contextType !== 'physical')
        .map(p => {
            const accounts = selectAccounts(
                uniqBy(p.catalogLabelsV2 as [], 'label.id.vendorId'),
                p.labelName
            );

            return {
                upc: p.upc || '',
                productName: p.productName || '',
                imageLocation:
                    selectImageUrl(
                        p.globalProduct?.publicImageUrl,
                        p.imageLocation,
                        isSMEProduct(p.notForDistribution)
                    ) || '',
                type: p.format || '',
                isPartOfCatalog: true,
                labelName: p.labelName || '',
                participants: selectLabelParticipations(p.labelParticipations),
                releaseDate: p.releaseDate || '',
                accounts,
            };
        });

    return {
        totalCount: data.allProductsSearch.totalCount,
        items,
    };
};

export interface ProductTrack {
    growthPercentage1Day: number | null;
    growthPercentage28Days: number | null;
    growthPercentage7Days: number | null;
    isrc: string;
    participants: Partial<Participant>[];
    trackName: string | null;
    volumeNumber: number | null;
    trackNumber: number | null;
    streams1Day: number | null;
    streams28Days: number | null;
    streams7Days: number | null;
    streamsAllTime: number | null;
}

interface ProductTracks {
    totalCount: number;
    productName: string;
    items: ProductTrack[];
}

export const selectProductTracks = (
    data: GlobalProductTracksQuery
): ProductTracks => {
    const tracks = filterData(data.globalProductByUpc?.catalogProduct.tracks);
    const totalCount =
        data.globalProductByUpc?.catalogProduct.numberOfTracks ?? 0;
    const productName =
        data.globalProductByUpc?.catalogProduct.productName ?? '';

    return {
        totalCount,
        productName,
        items: tracks.map(
            ({
                isrc,
                trackName,
                participations,
                trackNumber,
                volumeNumber,
                analytics,
            }) => {
                const participants = filterData(
                    participations?.map(p => {
                        const participant = p?.participant?.globalParticipant;

                        if (!participant?.id || !participant?.name)
                            return undefined;
                        return {
                            id: participant?.id,
                            name: participant?.name,
                        } as Participant;
                    })
                );

                return {
                    isrc: `${isrc?.toUpperCase()}`,
                    trackName,
                    trackNumber,
                    volumeNumber,
                    participants,
                    streams1Day:
                        analytics?.streams.growthPeriods[0]?.value ?? null,
                    streams7Days:
                        analytics?.streams.growthPeriods[1]?.value ?? null,
                    streams28Days:
                        analytics?.streams.growthPeriods[2]?.value ?? null,
                    streamsAllTime:
                        analytics?.streams.aggregate.allTime ?? null,
                    growthPercentage1Day:
                        analytics?.streams.growthPeriods[0]?.growthPercentage ??
                        null,
                    growthPercentage7Days:
                        analytics?.streams.growthPeriods[1]?.growthPercentage ??
                        null,
                    growthPercentage28Days:
                        analytics?.streams.growthPeriods[2]?.growthPercentage ??
                        null,
                };
            }
        ),
    };
};

const selectTopSoundRecordings = (data: TopSoundRecordings[]) => ({
    items: data.map(({ isrc, analytics }) => ({
        isrc,
        streams1Day: analytics?.streams.growthPeriods[0].value ?? null,
        streams7Days: analytics?.streams.growthPeriods[1].value ?? null,
        streams28Days: analytics?.streams.growthPeriods[2].value ?? null,
        streamsAllTime: analytics?.streams.aggregate.allTime ?? null,
        growthPercentage1Day:
            analytics?.streams.growthPeriods[0].growthPercentage ?? null,
        growthPercentage7Days:
            analytics?.streams.growthPeriods[1].growthPercentage ?? null,
        growthPercentage28Days:
            analytics?.streams.growthPeriods[2].growthPercentage ?? null,
    })),
});

export const selectMultiProductTracks = (
    data: MultiProductTracksQuery
): ProductTracks => {
    const tracks = filterData(data.globalProductByUpc?.catalogProduct.tracks);
    const totalCount =
        data.globalProductByUpc?.catalogProduct.numberOfTracks ?? 0;
    const productName =
        data.globalProductByUpc?.catalogProduct.productName ?? '';
    const topSoundRecordings = selectTopSoundRecordings(
        data.topGlobalSoundRecordings
    );

    return {
        totalCount,
        productName,
        items: tracks.map(
            ({
                isrc,
                trackName,
                trackNumber,
                volumeNumber,
                participations,
            }) => {
                const participants = filterData(
                    participations?.map(p => {
                        const participant = p?.participant?.globalParticipant;

                        if (!participant?.id || !participant?.name)
                            return undefined;
                        return {
                            id: participant?.id,
                            name: participant?.name,
                        } as Participant;
                    })
                );
                const soundRecordingData = topSoundRecordings.items.find(
                    s => s.isrc === isrc
                );

                return {
                    isrc: `${isrc}`,
                    trackName,
                    trackNumber,
                    volumeNumber,
                    participants,
                    streams1Day: soundRecordingData?.streams1Day ?? null,
                    streams7Days: soundRecordingData?.streams7Days ?? null,
                    streams28Days: soundRecordingData?.streams28Days ?? null,
                    streamsAllTime: soundRecordingData?.streamsAllTime ?? null,
                    growthPercentage1Day:
                        soundRecordingData?.growthPercentage1Day ?? null,
                    growthPercentage7Days:
                        soundRecordingData?.growthPercentage7Days ?? null,
                    growthPercentage28Days:
                        soundRecordingData?.growthPercentage28Days ?? null,
                };
            }
        ),
    };
};

export interface ProductTopTrack {
    tuid: string;
    isrc: string | null;
    trackName: string | null;
    imageUrl: string | null;
    growthPeriods: ProductTrackGrowthPeriod[] | null;
}

export interface ProductTrackGrowthPeriod {
    period: DeltaGrowthPeriod | null;
    value: number | null;
    totalForPeriod: number | null;
    percentageForPeriod: number | null;
}

interface PeriodTotals {
    [DeltaGrowthPeriod._1_DAY]: number | null;
    [DeltaGrowthPeriod._7_DAYS]: number | null;
    [DeltaGrowthPeriod._14_DAYS]: number | null;
    [DeltaGrowthPeriod._28_DAYS]: number | null;
    [DeltaGrowthPeriod._90_DAYS]: number | null;
    [DeltaGrowthPeriod._180_DAYS]: number | null;
    [DeltaGrowthPeriod._183_DAYS]: number | null;
    [DeltaGrowthPeriod._365_DAYS]: number | null;
    [DeltaGrowthPeriod._1_TO_2_MONTHS]: number | null;
    [DeltaGrowthPeriod._2_TO_3_MONTHS]: number | null;
    [DeltaGrowthPeriod._3_TO_4_MONTHS]: number | null;
    [DeltaGrowthPeriod._ALL_TIME]: number | null;
}

const calculateTotalsForPeriods = (tracks: TopTrack[]): PeriodTotals => {
    const growthPeriods = {
        [DeltaGrowthPeriod._1_DAY]: null,
        [DeltaGrowthPeriod._7_DAYS]: null,
        [DeltaGrowthPeriod._28_DAYS]: null,
        [DeltaGrowthPeriod._14_DAYS]: null,
        [DeltaGrowthPeriod._90_DAYS]: null,
        [DeltaGrowthPeriod._180_DAYS]: null,
        [DeltaGrowthPeriod._183_DAYS]: null,
        [DeltaGrowthPeriod._365_DAYS]: null,
        [DeltaGrowthPeriod._1_TO_2_MONTHS]: null,
        [DeltaGrowthPeriod._2_TO_3_MONTHS]: null,
        [DeltaGrowthPeriod._3_TO_4_MONTHS]: null,
        [DeltaGrowthPeriod._ALL_TIME]: null,
    };

    return tracks.reduce((acc: PeriodTotals, current) => {
        const periods = current.analytics?.streams?.growthPeriods;
        if (!periods) return acc;

        periods.map(period => {
            const currentPeriod = period.period;
            const currentValue = period.value;

            if (!currentPeriod || currentValue === null) return acc;

            const currentTotal = get(acc, currentPeriod);

            if (!currentTotal) acc[currentPeriod] = currentValue;
            else acc[currentPeriod] = currentTotal + currentValue;

            return acc;
        });

        return acc;
    }, growthPeriods);
};

const mapPeriodsWithTotalsAndPercentage = (
    periods: GrowthPeriod[],
    periodTotals: PeriodTotals
): ProductTrackGrowthPeriod[] =>
    periods.map(period => {
        const totalForPeriod = period?.period
            ? get(periodTotals, period.period)
            : null;
        const percentageForPeriod =
            period.value && totalForPeriod !== null && totalForPeriod > 0
                ? period.value / totalForPeriod
                : null;

        return {
            period: period.period ?? null,
            value: period.value ?? null,
            totalForPeriod,
            percentageForPeriod,
        };
    });

export const selectProductTopTracks = ({
    globalProductByUpc,
}: GlobalProductTopTracksQuery): ProductTopTrack[] | undefined => {
    if (!globalProductByUpc?.upc) return undefined;

    const { catalogProduct } = globalProductByUpc;
    const imageUrl = catalogProduct.imageLocation || '';
    const tracks = filterData(catalogProduct.tracks);
    const periodTotals = calculateTotalsForPeriods(tracks);

    return tracks.map(track => {
        const growthPeriods = track.analytics?.streams?.growthPeriods ?? [];
        const periodsWithTotalAndPercentage = mapPeriodsWithTotalsAndPercentage(
            growthPeriods,
            periodTotals
        );

        return {
            tuid: track.tuid,
            isrc: track.isrc ?? null,
            trackName: track.trackName ?? null,
            imageUrl: imageUrl ?? null,
            growthPeriods: periodsWithTotalAndPercentage,
        };
    });
};

export const selectOfferTypesIconKeys = (offerType: TrackOfferType) => {
    switch (offerType) {
        case TrackOfferType.ALBUM_DOWNLOAD_ONLY:
            return [
                {
                    name: 'S',
                    active: false,
                    term: 'product.offerTypeKeyMessage.streamingInactive',
                },
                {
                    name: 'TD',
                    active: false,
                    term: 'product.offerTypeKeyMessage.downloadsInactive',
                },
                {
                    name: 'AD',
                    active: true,
                    term: 'product.offerTypeKeyMessage.albumDownloadsActive',
                },
            ];
        case TrackOfferType.ALBUM_DOWNLOAD_STREAM:
            return [
                {
                    name: 'S',
                    active: true,
                    term: 'product.offerTypeKeyMessage.streamingActive',
                },
                {
                    name: 'TD',
                    active: false,
                    term: 'product.offerTypeKeyMessage.downloadsInactive',
                },
                {
                    name: 'AD',
                    active: true,
                    term: 'product.offerTypeKeyMessage.albumDownloadsActive',
                },
            ];
        case TrackOfferType.STREAM_ONLY:
            return [
                {
                    name: 'S',
                    active: true,
                    term: 'product.offerTypeKeyMessage.streamingActive',
                },
                {
                    name: 'TD',
                    active: false,
                    term: 'product.offerTypeKeyMessage.downloadsInactive',
                },
                {
                    name: 'AD',
                    active: false,
                    term: 'product.offerTypeKeyMessage.albumDownloadsInactive',
                },
            ];
        case TrackOfferType.TRACK_DOWNLOAD_STREAM:
            return [
                {
                    name: 'S',
                    active: true,
                    term: 'product.offerTypeKeyMessage.streamingActive',
                },
                {
                    name: 'TD',
                    active: true,
                    term: 'product.offerTypeKeyMessage.downloadsActive',
                },
                {
                    name: 'AD',
                    active: false,
                    term: 'product.offerTypeKeyMessage.albumDownloadsInactive',
                },
            ];
        case TrackOfferType.NONE:
            return [
                {
                    name: 'S',
                    active: false,
                    term: 'product.offerTypeKeyMessage.streamingInactive',
                },
                {
                    name: 'TD',
                    active: false,
                    term: 'product.offerTypeKeyMessage.downloadsInactive',
                },
                {
                    name: 'AD',
                    active: false,
                    term: 'product.offerTypeKeyMessage.albumDownloadsInactive',
                },
            ];
        case TrackOfferType.ALL:
            return [
                {
                    name: 'S',
                    active: true,
                    term: 'product.offerTypeKeyMessage.streamingActive',
                },
                {
                    name: 'TD',
                    active: true,
                    term: 'product.offerTypeKeyMessage.downloadsActive',
                },
                {
                    name: 'AD',
                    active: true,
                    term: 'product.offerTypeKeyMessage.albumDownloadsActive',
                },
            ];
        case TrackOfferType.ALBUM_TRACK_DOWNLOAD:
            return [
                {
                    name: 'S',
                    active: false,
                    term: 'product.offerTypeKeyMessage.streamingInactive',
                },
                {
                    name: 'TD',
                    active: true,
                    term: 'product.offerTypeKeyMessage.downloadsActive',
                },
                {
                    name: 'AD',
                    active: true,
                    term: 'product.offerTypeKeyMessage.albumDownloadsActive',
                },
            ];
        case TrackOfferType.TRACK_DOWNLOAD_ONLY:
            return [
                {
                    name: 'S',
                    active: false,
                    term: 'product.offerTypeKeyMessage.streamingInactive',
                },
                {
                    name: 'TD',
                    active: true,
                    term: 'product.offerTypeKeyMessage.downloadsActive',
                },
                {
                    name: 'AD',
                    active: false,
                    term: 'product.offerTypeKeyMessage.albumDownloadsInactive',
                },
            ];
        default:
            return [
                {
                    name: 'S',
                    active: true,
                    term: 'product.offerTypeKeyMessage.streamingActive',
                },
                {
                    name: 'TD',
                    active: true,
                    term: 'product.offerTypeKeyMessage.downloadsActive',
                },
                {
                    name: 'AD',
                    active: true,
                    term: 'product.offerTypeKeyMessage.albumDownloadsActive',
                },
            ];
    }
};

export interface ProductStats {
    allTime: number;
    growth: number;
}

export interface ProductResultItem {
    upc: string;
    format: string;
    imageLocation: string | null;
    imprint: string | null;
    productName: string;
    labelName: string;
    releaseDate: string;
    streamsAllTime?: number | null;
    streams1Day?: number;
    streams7Days?: number;
    streams28Days?: number;
    growthPercentage1Day?: number;
    growthPercentage7Days?: number;
    growthPercentage28Days?: number;
    participants: Partial<Participant>[];
    deleted?: boolean;
    accounts?: Account[];
}

export interface ProductsResults {
    totalCount: number;
    products: ProductResultItem[];
}

export const selectTopProductResults = (
    data: TopProductResultsQuery | undefined
): ProductsResults => {
    const totalCount = data?.topProductResults?.totalProducts ?? 0;

    return {
        totalCount,
        products: filterData(data?.topProductResults?.products).map(
            ({
                upc,
                productName,
                imageLocation,
                globalProduct,
                notForDistribution,
                releaseDate,
                labelName,
                analytics,
                labelParticipations,
                catalogLabelsV2,
                format,
                deletions,
                imprint,
            }) => ({
                upc: upc || '',
                format: format || '',
                imageLocation:
                    selectImageUrl(
                        globalProduct?.publicImageUrl,
                        imageLocation,
                        isSMEProduct(notForDistribution)
                    ) || '',
                imprint,
                productName: productName || '',
                releaseDate: releaseDate || '',
                labelName: labelName || '',
                deleted: deletions === 'Y',
                participants: selectLabelParticipations(labelParticipations),
                isPartOfCatalog: true,
                streams1Day:
                    analytics?.streams?.growthPeriods?.[0]?.value ?? undefined,
                streams7Days:
                    analytics?.streams?.growthPeriods?.[1]?.value ?? undefined,
                streams28Days:
                    analytics?.streams?.growthPeriods?.[2]?.value ?? undefined,
                streamsAllTime:
                    analytics?.streams?.aggregate?.allTime ?? undefined,
                growthPercentage1Day:
                    analytics?.streams?.growthPeriods?.[0]?.growthPercentage ??
                    undefined,
                growthPercentage7Days:
                    analytics?.streams?.growthPeriods?.[1]?.growthPercentage ??
                    undefined,
                growthPercentage28Days:
                    analytics?.streams?.growthPeriods?.[2]?.growthPercentage ??
                    undefined,
                accounts: selectAccounts(
                    uniqBy(catalogLabelsV2, 'label.id.vendorId'),
                    labelName
                ),
            })
        ),
    };
};

export interface StarredProduct {
    upc: string;
    productName: string | null;
    imageLocation: string | null;
    isPartOfCatalog: boolean;
    participants: Partial<Participant>[];
    labelName: string | null;
    releaseDate: string | null;
    following: boolean;
    dateFollowed: string | null;
}

export interface StarredProductStreams {
    upc: string;
    streamsAllTime: number | null;
}

export const selectStarredProducts = (
    data: StarredGlobalProductsQuery
): StarredProduct[] => {
    const starredProducts = data.starredGlobalProducts;
    return starredProducts.map(
        ({
            upc,
            catalogProduct,
            isPartOfCatalog,
            following,
            dateFollowed,
            publicImageUrl,
        }) => {
            const participants = selectLabelParticipations(
                catalogProduct.labelParticipations
            );

            return {
                upc,
                productName: catalogProduct.productName,
                imageLocation:
                    selectImageUrl(
                        publicImageUrl,
                        catalogProduct.imageLocation,
                        isSMEProduct(catalogProduct.notForDistribution)
                    ) || '',
                isPartOfCatalog,
                participants,
                labelName: catalogProduct.labelName,
                releaseDate: catalogProduct.releaseDate,
                following,
                dateFollowed,
            };
        }
    );
};

export const selectStarredProductsStreams = (
    data: StarredGlobalProductsStreamsQuery
): StarredProductStreams[] =>
    data.starredGlobalProducts.map(({ upc, catalogProduct }) => ({
        upc,
        streamsAllTime:
            catalogProduct.analytics?.streams.aggregate.allTime ?? null,
    }));

export const selectProductAggregateStreams = (
    aggregateStreams: ProductAggregateStreamsQuery
): ProductStats => ({
    allTime:
        aggregateStreams.globalProductByUpc?.catalogProduct.analytics
            ?.aggregateStreams.allTime ?? 0,
    growth:
        aggregateStreams.globalProductByUpc?.catalogProduct.analytics
            ?.aggregateStreams.growthPercentage7Days.growthPercentage ?? 0,
});

export const selectCompany = (data: CompanyBrands | undefined) => {
    if (!data) return [];

    const brandsData = data.companyBrands.filter(
        ({ name }) => name !== BRAND_KNR
    );

    const parentCompanies = uniqBy(
        brandsData
            .filter(item => item.parentCompany)
            .map(item => ({
                uuid: item.parentCompany?.uuid || item.uuid,
                name: item.parentCompany?.name || item.name,
                logoUrl:
                    getCompanyIcon(item.parentCompany?.name) || item.logoUrl,
                displayName:
                    item.parentCompany?.displayName || item.displayName,
                parentCompany: undefined,
                type: 'parentCompany',
            })),
        'uuid'
    );

    const brands = brandsData.map(brand => ({
        uuid: brand.uuid,
        name: brand.name,
        logoUrl: brand.logoUrl,
        displayName: brand.displayName,
        parentCompany: nonNullable(brand.parentCompany),
        type: 'brand',
    }));

    return parentCompanies
        .map(item => [
            item,
            ...brands.filter(brand => brand.parentCompany?.uuid === item.uuid),
        ])
        .flat();
};
