import { OTHER } from 'src/constants/index';

export const SourceTypes = [
    'streams',
    'skips',
    'saves',
    'downloads',
    'views',
    'sos',
] as const;
export type SourceType = (typeof SourceTypes)[number];
export const STORE_ALL = 'allStores';

export interface SourceError {
    code?: string;
    types: string[];
    message?: string;
}

export interface Source {
    storeName: string;
    storeId: number;
    error?: SourceError;
    latestDate?: string;
}

export interface StoreSource extends Source {
    errors: SourceError[];
}

export const SOURCE_STREAMS = SourceTypes[0];
export const SOURCE_SKIPS = SourceTypes[1];
export const SOURCE_SAVES = SourceTypes[2];
export const SOURCE_DOWNLOADS = SourceTypes[3];
export const SOURCE_VIEWS = SourceTypes[4];
export const SOURCE_SOS = SourceTypes[5];

export const TYPE_SKIPS_SAVES = 'skips_saves';
export const TYPE_STREAMS = 'streams';
export const TYPE_DOWNLOADS = 'downloads';
export const TYPE_VIEWS = 'views';
export const TYPE_SOS = 'source_of_streams';

export const STORE_ID_APPLE_MUSIC = 1;
export const STORE_ID_NAPSTER = 4;
export const STORE_ID_SPOTIFY = 286;
export const STORE_ID_DEEZER = 348;
export const STORE_ID_YOUTUBE = 453;
export const STORE_ID_GOOGLE_PLAY = 496;
export const STORE_ID_PANDORA = 708;
export const STORE_ID_AMAZON_MUSIC = 187;
export const STORE_ID_LINE = 1503;
export const STORE_ID_SOUNDCLOUD = 1505;
export const STORE_ID_AWA = 1600;
export const STORE_ID_WYNK = 1260;
export const STORE_ID_BEATPORT = 213;
export const STORE_ID_QQ = 1603;
export const STORE_ID_KUGOU = 1602;
export const STORE_ID_KUWO = 1601;
export const STORE_ID_RECOCHOKU = 1414;
export const STORE_ID_SHAZAM = 358;
export const STORE_ID_TIKTOK = 1202;

export const STORE_APPLE_MUSIC = 'appleMusic';
export const STORE_NAPSTER = 'napster';
export const STORE_SPOTIFY = 'spotify';
export const STORE_DEEZER = 'deezer';
export const STORE_GOOGLE = 'google';
export const STORE_GOOGLE_PLAY = 'googlePlay';
export const STORE_PANDORA = 'pandora';
export const STORE_AMAZON_MUSIC = 'amazonMusic';
export const STORE_AMAZON = 'amazon';
export const STORE_ITUNES = 'itunes';
export const STORE_LINE = 'line';
export const STORE_LINE_MUSIC = 'line';
export const STORE_LINE_MUSIC_LONG = 'linemusic';
export const STORE_RECOCHOKU = 'recochoku';
export const STORE_FACEBOOK = 'facebook';
export const STORE_TWITTER = 'twitter';
export const STORE_X = 'x';
export const STORE_SOUNDCLOUD = 'soundcloud';
export const STORE_YOUTUBE = 'youtube';
export const STORE_INSTAGRAM = 'instagram';
export const STORE_TIKTOK = 'tiktok';
export const STORE_AWA = 'awa';
export const STORE_WYNK = 'wynk';
export const STORE_BEATPORT = 'beatport';
export const STORE_QQ = 'qq';
export const STORE_KUGOU = 'kugou';
export const STORE_KUWO = 'kuwo';
export const STORE_SHAZAM = 'shazam';
export const STORE_CHARTABLE = 'chartable';
export const STORE_META = 'meta';
export const STORE_SHOPIFY = 'shopify';
export const STORE_STITCHER = 'stitcher';

const STORE_TITLE_APPLE_MUSIC = 'Apple Music';
const STORE_TITLE_NAPSTER = 'Napster';
const STORE_TITLE_SPOTIFY = 'Spotify';
const STORE_TITLE_DEEZER = 'Deezer';
const STORE_TITLE_GOOGLE_PLAY = 'Google Play';
const STORE_TITLE_PANDORA = 'Pandora';
const STORE_TITLE_AMAZON_MUSIC = 'Amazon Music';
export const STORE_TITLE_AMAZON = 'Amazon';
const STORE_TITLE_LINE = 'LINE Japan';
const STORE_TITLE_FACEBOOK = 'Facebook';
const STORE_TITLE_TWITTER = 'Twitter';
export const STORE_TITLE_X = 'X';
const STORE_TITLE_SOUNDCLOUD = 'SoundCloud';
const STORE_TITLE_YOUTUBE = 'YouTube';
const STORE_TITLE_INSTAGRAM = 'Instagram';
const STORE_TITLE_TIKTOK = 'TikTok';
const STORE_TITLE_AWA = 'AWA';
const STORE_TITLE_WYNK = 'WYNK';
const STORE_TITLE_BEATPORT = 'Beatport';
const STORE_TITLE_QQ = 'QQ';
const STORE_TITLE_KUGOU = 'Kugou';
const STORE_TITLE_KUWO = 'Kuwo';
const STORE_TITLE_SHAZAM = 'Shazam';
const STORE_TITLE_RECOCHOKU = 'Recochoku';

export const STORE_TITLES: Record<string, string> = {
    [STORE_APPLE_MUSIC]: STORE_TITLE_APPLE_MUSIC,
    [STORE_NAPSTER]: STORE_TITLE_NAPSTER,
    [STORE_SPOTIFY]: STORE_TITLE_SPOTIFY,
    [STORE_DEEZER]: STORE_TITLE_DEEZER,
    [STORE_GOOGLE_PLAY]: STORE_TITLE_GOOGLE_PLAY,
    [STORE_PANDORA]: STORE_TITLE_PANDORA,
    [STORE_AMAZON_MUSIC]: STORE_TITLE_AMAZON_MUSIC,
    [STORE_AMAZON]: STORE_TITLE_AMAZON,
    [STORE_LINE]: STORE_TITLE_LINE,
    [STORE_FACEBOOK]: STORE_TITLE_FACEBOOK,
    [STORE_TWITTER]: STORE_TITLE_TWITTER,
    [STORE_SOUNDCLOUD]: STORE_TITLE_SOUNDCLOUD,
    [STORE_YOUTUBE]: STORE_TITLE_YOUTUBE,
    [STORE_INSTAGRAM]: STORE_TITLE_INSTAGRAM,
    [STORE_TIKTOK]: STORE_TITLE_TIKTOK,
    [STORE_AWA]: STORE_TITLE_AWA,
    [STORE_WYNK]: STORE_TITLE_WYNK,
    [STORE_BEATPORT]: STORE_TITLE_BEATPORT,
    [STORE_QQ]: STORE_TITLE_QQ,
    [STORE_KUGOU]: STORE_TITLE_KUGOU,
    [STORE_KUWO]: STORE_TITLE_KUWO,
    [STORE_SHAZAM]: STORE_TITLE_SHAZAM,
    [STORE_RECOCHOKU]: STORE_TITLE_RECOCHOKU,
};

export const STORE_IDS = [
    STORE_ID_APPLE_MUSIC,
    STORE_ID_NAPSTER,
    STORE_ID_SPOTIFY,
    STORE_ID_DEEZER,
    STORE_ID_GOOGLE_PLAY,
    STORE_ID_PANDORA,
    STORE_ID_AMAZON_MUSIC,
    STORE_ID_LINE,
    STORE_ID_SOUNDCLOUD,
    STORE_ID_YOUTUBE,
    STORE_ID_AWA,
    STORE_ID_WYNK,
    STORE_ID_BEATPORT,
    STORE_ID_QQ,
    STORE_ID_KUGOU,
    STORE_ID_KUWO,
];

export const STORE_SOURCES: Record<number, string[]> = {
    [STORE_ID_SPOTIFY]: [TYPE_STREAMS, TYPE_SKIPS_SAVES, TYPE_SOS],
    [STORE_ID_APPLE_MUSIC]: [
        TYPE_STREAMS,
        TYPE_SKIPS_SAVES,
        TYPE_DOWNLOADS,
        TYPE_SOS,
    ],
    [STORE_ID_AMAZON_MUSIC]: [
        TYPE_STREAMS,
        TYPE_SKIPS_SAVES,
        TYPE_DOWNLOADS,
        TYPE_SOS,
    ],
    [STORE_ID_GOOGLE_PLAY]: [TYPE_STREAMS, TYPE_DOWNLOADS],
    [STORE_ID_LINE]: [TYPE_STREAMS, TYPE_SKIPS_SAVES],
    [STORE_ID_SOUNDCLOUD]: [TYPE_STREAMS, TYPE_SKIPS_SAVES],
    [STORE_ID_YOUTUBE]: [TYPE_VIEWS],
    [STORE_ID_AWA]: [TYPE_STREAMS],
    [STORE_ID_WYNK]: [TYPE_STREAMS],
    [STORE_ID_BEATPORT]: [TYPE_DOWNLOADS],
    [STORE_ID_QQ]: [TYPE_STREAMS],
    [STORE_ID_KUGOU]: [TYPE_STREAMS],
    [STORE_ID_KUWO]: [TYPE_STREAMS],
};

export const STORE_DISPLAY_NAMES_BY_ID: Record<number, string> = {
    [STORE_ID_AMAZON_MUSIC]: STORE_TITLE_AMAZON_MUSIC,
    [STORE_ID_APPLE_MUSIC]: STORE_TITLE_APPLE_MUSIC,
    [STORE_ID_DEEZER]: STORE_TITLE_DEEZER,
    [STORE_ID_GOOGLE_PLAY]: STORE_TITLE_GOOGLE_PLAY,
    [STORE_ID_NAPSTER]: STORE_TITLE_NAPSTER,
    [STORE_ID_PANDORA]: STORE_TITLE_PANDORA,
    [STORE_ID_SPOTIFY]: STORE_TITLE_SPOTIFY,
    [STORE_ID_LINE]: STORE_TITLE_LINE,
    [STORE_ID_SOUNDCLOUD]: STORE_TITLE_SOUNDCLOUD,
    [STORE_ID_YOUTUBE]: STORE_TITLE_YOUTUBE,
    [STORE_ID_AWA]: STORE_TITLE_AWA,
    [STORE_ID_WYNK]: STORE_TITLE_WYNK,
    [STORE_ID_BEATPORT]: STORE_TITLE_BEATPORT,
    [STORE_ID_QQ]: STORE_TITLE_QQ,
    [STORE_ID_KUGOU]: STORE_TITLE_KUGOU,
    [STORE_ID_KUWO]: STORE_TITLE_KUWO,
    [STORE_ID_RECOCHOKU]: STORE_TITLE_RECOCHOKU,
    [STORE_ID_SHAZAM]: STORE_TITLE_SHAZAM,
    [STORE_ID_TIKTOK]: STORE_TITLE_TIKTOK,
};

export const STORES_FILTER_BY = Object.entries(STORE_DISPLAY_NAMES_BY_ID).map(
    ([storeId, name]) => ({ storeId: Number(storeId), name })
);

export const STORES_BY_ID: Record<number, string> = {
    [STORE_ID_AMAZON_MUSIC]: STORE_AMAZON_MUSIC,
    [STORE_ID_APPLE_MUSIC]: STORE_APPLE_MUSIC,
    [STORE_ID_DEEZER]: STORE_DEEZER,
    [STORE_ID_GOOGLE_PLAY]: STORE_GOOGLE_PLAY,
    [STORE_ID_NAPSTER]: STORE_NAPSTER,
    [STORE_ID_PANDORA]: STORE_PANDORA,
    [STORE_ID_SPOTIFY]: STORE_SPOTIFY,
    [STORE_ID_LINE]: STORE_LINE,
    [STORE_ID_SOUNDCLOUD]: STORE_SOUNDCLOUD,
    [STORE_ID_YOUTUBE]: STORE_YOUTUBE,
    [STORE_ID_AWA]: STORE_AWA,
    [STORE_ID_WYNK]: STORE_WYNK,
    [STORE_ID_BEATPORT]: STORE_BEATPORT,
    [STORE_ID_QQ]: STORE_QQ,
    [STORE_ID_KUGOU]: STORE_KUGOU,
    [STORE_ID_KUWO]: STORE_KUWO,
    [STORE_ID_TIKTOK]: STORE_TIKTOK,
    [STORE_ID_RECOCHOKU]: STORE_RECOCHOKU,
    [STORE_ID_SHAZAM]: STORE_SHAZAM,
};

export const STORE_IDS_BY_NAME: Record<string, number> = {
    [STORE_AMAZON_MUSIC]: STORE_ID_AMAZON_MUSIC,
    [STORE_AMAZON]: STORE_ID_AMAZON_MUSIC,
    [STORE_APPLE_MUSIC]: STORE_ID_APPLE_MUSIC,
    [STORE_DEEZER]: STORE_ID_DEEZER,
    [STORE_GOOGLE_PLAY]: STORE_ID_GOOGLE_PLAY,
    [STORE_NAPSTER]: STORE_ID_NAPSTER,
    [STORE_PANDORA]: STORE_ID_PANDORA,
    [STORE_SPOTIFY]: STORE_ID_SPOTIFY,
    [STORE_LINE]: STORE_ID_LINE,
    [STORE_SOUNDCLOUD]: STORE_ID_SOUNDCLOUD,
    [STORE_YOUTUBE]: STORE_ID_YOUTUBE,
    [STORE_AWA]: STORE_ID_AWA,
    [STORE_WYNK]: STORE_ID_WYNK,
    [STORE_BEATPORT]: STORE_ID_BEATPORT,
    [STORE_QQ]: STORE_ID_QQ,
    [STORE_KUGOU]: STORE_ID_KUGOU,
    [STORE_KUWO]: STORE_ID_KUWO,
    [STORE_RECOCHOKU]: STORE_ID_RECOCHOKU,
    [STORE_SHAZAM]: STORE_ID_SHAZAM,
    [STORE_TIKTOK]: STORE_ID_TIKTOK,
};

export const getSourcesStore = (store?: number[]) =>
    store?.some(
        storeId =>
            storeId === STORE_ID_APPLE_MUSIC ||
            storeId === STORE_ID_SPOTIFY ||
            storeId === STORE_ID_AMAZON_MUSIC
    ) && store?.length === 1;

export const STORE_AMAZON_MUSIC_OSP_CLASSNAME = 'amazon-music';
export const STORE_APPLE_MUSIC_OSP_CLASSNAME = 'apple-music';
export const STORE_APPLE_PODCAST_OSP_CLASSNAME = 'apple-podcast';
export const STORE_GOOGLE_PLAY_OSP_CLASSNAME = 'google-play';
export const STORE_LINE_JAPAN_OSP_CLASSNAME = 'line-japan';
export const STORES_CLASSNAMES_OSP_BACKWARD_COMPATIBILITY: Record<
    string,
    string
> = {
    [STORE_AWA]: STORE_AWA,
    [STORE_AMAZON_MUSIC]: STORE_AMAZON_MUSIC_OSP_CLASSNAME,
    [STORE_APPLE_MUSIC]: STORE_APPLE_MUSIC_OSP_CLASSNAME,
    [STORE_APPLE_PODCAST_OSP_CLASSNAME]: STORE_APPLE_PODCAST_OSP_CLASSNAME, // There is no own store `const` for Apple Podcasts
    [STORE_BEATPORT]: STORE_BEATPORT,
    [STORE_CHARTABLE]: STORE_CHARTABLE,
    [STORE_DEEZER]: STORE_DEEZER,
    [STORE_FACEBOOK]: STORE_FACEBOOK,
    [STORE_GOOGLE_PLAY]: STORE_GOOGLE_PLAY_OSP_CLASSNAME,
    [STORE_INSTAGRAM]: STORE_INSTAGRAM,
    [STORE_KUGOU]: STORE_KUGOU,
    [STORE_KUWO]: STORE_KUWO,

    // LINE Music and LINE Japan are the same store
    [STORE_LINE]: STORE_LINE_JAPAN_OSP_CLASSNAME,
    [STORE_LINE_MUSIC]: STORE_LINE_JAPAN_OSP_CLASSNAME,
    [STORE_LINE_MUSIC_LONG]: STORE_LINE_JAPAN_OSP_CLASSNAME,
    // end LINE Music and LINE Japan

    [STORE_META]: STORE_META,
    [STORE_NAPSTER]: STORE_NAPSTER,
    [STORE_PANDORA]: STORE_PANDORA,
    [STORE_QQ]: STORE_QQ,
    [STORE_RECOCHOKU]: STORE_RECOCHOKU,
    [STORE_SHAZAM]: STORE_SHAZAM,
    [STORE_SHOPIFY]: STORE_SHOPIFY,
    [STORE_SOUNDCLOUD]: STORE_SOUNDCLOUD,
    [STORE_SPOTIFY]: STORE_SPOTIFY,
    [STORE_STITCHER]: STORE_STITCHER,
    [STORE_TIKTOK]: STORE_TIKTOK,
    [STORE_TWITTER]: STORE_TWITTER,
    [STORE_WYNK]: STORE_WYNK,
    [STORE_YOUTUBE]: STORE_YOUTUBE,

    [OTHER]: OTHER,
};
