import { formatMessage } from '@theorchard/suite-frontend';
import FailedToLoad from 'src/components/error/components/failedToLoad';
import NoResultsFiltering from 'src/components/error/components/noResultsFiltering';
import { SortDirection } from 'src/components/table/types';
import { ERROR_TYPES } from 'src/constants';

export const VIDEOS_PAGE_SIZE = 50;

export const TOP_VIDEOS_TYPE = {
    ACCOUNT: 'account',
    CATALOG: 'catalog',
};

export const VIDEO_ID = 'video.id';
export const VIDEO_NAME = 'video.video';
export const CHANNEL_NAME = 'channel.channel';
export const LABEL_NAME = 'catalog.labelName';
export const COUNTRY = 'country.country';
export const ALL_TIME_VIEWS = 'catalog.allTimeViews';
export const ALL_TIME_REVENUE = () =>
    `${formatMessage('catalog.allTimeRevenue')} ($)`;
export const AVG_VIEW_DURATION = 'catalog.avgViewDuration';
export const MONTH_VIEWS = (month: string) =>
    formatMessage('catalog.monthViews', { month });
export const MONTH_CHANGE = (month: string) =>
    formatMessage('catalog.monthChange', { month });
export const ARTIST_NAME = 'catalog.artistName';

export const DEFAULT_SORT_FIELD = 'views_1_month_back';
export const DEFAULT_SORT_DIR: SortDirection = 'desc';

export const CATALOG_VIDEOS_SORTING_FIELDS_MAP = {
    allTimeViews: 'total_views',
    allTimeRevenue: 'total_estimated_gross_revenue',
    avgViewDuration: 'average_view_duration_seconds',
    month1Views: 'views_1_month_back',
    month1Change: 'growth_2_to_1_months_back',
    month2Views: 'views_2_months_back',
    month2Change: 'growth_3_to_2_months_back',
    month3Views: 'views_3_months_back',
    month3Change: 'growth_4_to_3_months_back',
    viewsLast7Days: 'views_last_7_days',
    viewsLast28Days: 'views_last_28_days',
};

export const ERROR_CONFIG = {
    [ERROR_TYPES.ERROR]: FailedToLoad,
    [ERROR_TYPES.NO_RESULTS]: NoResultsFiltering,
};
