import React, { FC } from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import cx from 'classnames';
import { VideoSearchResult } from 'src/apollo/selectors';
import { Entity } from 'src/apollo/selectors/types';
import { useEmployeeIdentity } from 'src/apollo/utils';
import GridTable from 'src/components/gridTable';
import CatalogLoadingIndicator from 'src/components/table/cells/loadingIndicators/catalogLoadingIndicator';
import EntityLoadingIndicator from 'src/components/table/cells/loadingIndicators/entityLoadingIndicator';
import PillLoadingIndicator from 'src/components/table/cells/loadingIndicators/pillLoadingIndicator';
import SingleLineLoadingIndicator from 'src/components/table/cells/loadingIndicators/singleLineLoadingIndicator';
import {
    PAGINATED_LIMIT_OPTIONS,
    TABLE_RESULTS_COMMON_CLASSNAME,
} from 'src/pages/searchES/constants';
import { TableCommonProps } from 'src/pages/searchES/pages/table';

export const CLASSNAME = 'VideoResultsGridTable';
export const CLASSNAME_V2 = 'VideoResultsGridTableV2';
export const TEST_ID = CLASSNAME;

export interface VideoSearchResultTableData extends VideoSearchResult {
    video: Entity;
}

export interface Props extends TableCommonProps {
    loading?: boolean;
    data?: VideoSearchResultTableData[];
}

const defaultColumnDefs = {
    sortable: false,
};

const VideoResultsGridTable: FC<Props> = ({
    loading,
    data,
    totalCount,
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
    paginated,
}) => {
    const { isEmployee } = useEmployeeIdentity();
    const isNoData = !loading && !data?.length;
    const handledData = loading ? [] : data;

    return (
        <GridTable
            name={CLASSNAME}
            className={cx(
                CLASSNAME,
                CLASSNAME_V2,
                TABLE_RESULTS_COMMON_CLASSNAME
            )}
            testId={TEST_ID}
            data={handledData}
            loading={loading}
            sortBy={{ key: '', direction: 'asc' }}
            defaultColumnDefs={defaultColumnDefs}
            rowKey={({ videoId, isPartOfCatalog }) =>
                `${videoId}-${isPartOfCatalog}`
            }
            totalCount={totalCount}
            page={page}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            paginated={paginated && !isNoData}
            pageSizeOptions={PAGINATED_LIMIT_OPTIONS}
            showUpdateIndicator
            stickyHeader
            exportable={false}
            customizable={false}
        >
            <GridTable.Column
                name="inCatalog"
                template="inCatalog"
                minWidth={'36px'}
                LoadingIndicator={CatalogLoadingIndicator}
            />
            <GridTable.Column
                name="video"
                title={formatMessage('video.video')}
                template="thumbnailVideoLinkV2"
                minWidth="604px"
                maxWidth="2fr"
                sortable={false}
                LoadingIndicator={() => (
                    <EntityLoadingIndicator
                        type={'landscape'}
                        withLabel={false}
                    />
                )}
            />
            <GridTable.Column
                name="youtubeChannel"
                title={formatMessage('channel.channel')}
                template="channelLinkCell"
                minWidth="200px"
                maxWidth="2fr"
                sortable={false}
                LoadingIndicator={SingleLineLoadingIndicator}
            />
            <GridTable.Column
                name="labelName"
                title={formatMessage('account.account')}
                template="accountDetails"
                minWidth="200px"
                maxWidth="2fr"
                sortable={false}
                visibility={isEmployee ? 'visible' : 'hidden'}
                LoadingIndicator={PillLoadingIndicator}
            />
            <GridTable.Column
                name="videoId"
                title={formatMessage('video.id')}
                template="truncatedText"
                minWidth="130px"
                maxWidth="1fr"
                sortable={false}
                LoadingIndicator={SingleLineLoadingIndicator}
            />
        </GridTable>
    );
};

export default VideoResultsGridTable;
