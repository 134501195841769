import React, { VFC } from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import cx from 'classnames';
import { ChannelSearchResultItem } from 'src/apollo/selectors';
import { Entity } from 'src/apollo/selectors/types';
import { useEmployeeIdentity } from 'src/apollo/utils';
import GridTable from 'src/components/gridTable';
import CatalogLoadingIndicator from 'src/components/table/cells/loadingIndicators/catalogLoadingIndicator';
import EntityLoadingIndicator from 'src/components/table/cells/loadingIndicators/entityLoadingIndicator';
import PillLoadingIndicator from 'src/components/table/cells/loadingIndicators/pillLoadingIndicator';
import SingleLineLoadingIndicator from 'src/components/table/cells/loadingIndicators/singleLineLoadingIndicator';
import {
    PAGINATED_LIMIT_OPTIONS,
    TABLE_RESULTS_COMMON_CLASSNAME,
} from 'src/pages/searchES/constants';
import { TableCommonProps } from 'src/pages/searchES/pages/table';

export const CLASSNAME = 'ChannelResultsGridTable';
export const CLASSNAME_V2 = 'ChannelResultsGridTableV2';
export const TEST_ID = CLASSNAME;

export interface ChannelSearchResultTableData extends ChannelSearchResultItem {
    channel: Entity;
}

export interface Props extends TableCommonProps {
    loading?: boolean;
    data?: ChannelSearchResultTableData[];
}

const defaultColumnDefs = {
    sortable: false,
};

const ChannelResultsGridTable: VFC<Props> = ({
    loading,
    data,
    paginated,
    totalCount,
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
}) => {
    const { isEmployee } = useEmployeeIdentity();
    const isNoData = !loading && !data?.length;
    const handledData = loading ? [] : data;

    return (
        <GridTable
            name={CLASSNAME}
            className={cx(
                CLASSNAME,
                CLASSNAME_V2,
                TABLE_RESULTS_COMMON_CLASSNAME
            )}
            testId={TEST_ID}
            data={handledData}
            sortBy={{ key: '', direction: 'asc' }}
            defaultColumnDefs={defaultColumnDefs}
            rowKey={({ channelId, isPartOfCatalog }) =>
                `${channelId}-${isPartOfCatalog}`
            }
            loading={loading}
            totalCount={totalCount}
            page={page}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            paginated={paginated && !isNoData}
            pageSizeOptions={PAGINATED_LIMIT_OPTIONS}
            showUpdateIndicator
            stickyHeader
            exportable={false}
            customizable={false}
        >
            <GridTable.Column
                name="inCatalog"
                template="inCatalog"
                minWidth={'36px'}
                LoadingIndicator={CatalogLoadingIndicator}
            />
            <GridTable.Column
                name="video"
                title={formatMessage('channel.youtubeChannel')}
                template="thumbnailChannelLink"
                minWidth="804px"
                sortable={false}
                LoadingIndicator={() => (
                    <EntityLoadingIndicator withLabel={false} />
                )}
            />
            <GridTable.Column
                name="labelName"
                title={formatMessage('account.account')}
                template="accountDetails"
                minWidth="200px"
                maxWidth="2fr"
                sortable={false}
                visibility={isEmployee ? 'visible' : 'hidden'}
                LoadingIndicator={PillLoadingIndicator}
            />
            <GridTable.Column
                name="channelId"
                title={formatMessage('channel.id')}
                template="truncatedText"
                minWidth="130px"
                maxWidth="max-content"
                sortable={false}
                LoadingIndicator={SingleLineLoadingIndicator}
            />
        </GridTable>
    );
};

export default ChannelResultsGridTable;
