import React from 'react';
import { SkeletonLoader } from '@theorchard/suite-components';
import cx from 'classnames';
import { LoadingIndicatorCommonProps } from 'src/components/table/cells/loadingIndicators/loadingIndicators';

const CLASSNAME = 'pillLoadingIndicator';

const PillLoadingIndicator: React.FC<LoadingIndicatorCommonProps> = ({
    className,
}) => {
    return (
        <div className={cx(CLASSNAME, className)}>
            <SkeletonLoader className={`${CLASSNAME}__skeleton`} />
        </div>
    );
};

export default PillLoadingIndicator;
