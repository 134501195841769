import { QueryHookOptions, useQuery } from '@apollo/client';
import { PreSavedStores } from '../definitions/PreSavedStores';
import { usePrefetchQuery } from '../utils';
import PreSavedStoresQuery from './preSavedStores.gql';

export const usePreSavedStoresQuery = (
    options?: QueryHookOptions<PreSavedStores>
) => {
    const { data, loading, error } = useQuery<PreSavedStores>(
        PreSavedStoresQuery,
        {
            fetchPolicy: 'cache-first',
            ...options,
        }
    );

    const preSavedStores =
        data?.activeProfile.preSavedStores?.map(preSavedStore =>
            preSavedStore.storeId.toString()
        ) ?? [];

    return {
        data: preSavedStores,
        loading,
        error,
    };
};

export const usePrefetchPreSavedStoresQuery = () => {
    usePrefetchQuery<PreSavedStores>(PreSavedStoresQuery, {
        variables: {},
    });
};
