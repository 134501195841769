import React, { VFC } from 'react';
import { createHref } from '@theorchard/suite-frontend';
import { Redirect } from 'react-router-dom';
import { useAccountLevelPermissionAccess } from 'src/apollo/utils/useAccountLevelPermissionAccess';
import { ROUTE_CATALOG } from 'src/constants';
import { PAGE_ACCOUNTS, PAGE_SONGS } from 'src/constants/page';

const CatalogRedirect: VFC = () => {
    const hasAccountLevelPermission = useAccountLevelPermissionAccess();

    const defaultPage = hasAccountLevelPermission ? PAGE_ACCOUNTS : PAGE_SONGS;
    return (
        <Redirect
            to={createHref(ROUTE_CATALOG, { page: defaultPage })}
            push={false}
        />
    );
};

export default CatalogRedirect;
