import React, { FC } from 'react';
import { GridTableSortBy } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import cx from 'classnames';
import { ParticipantSearchResultItem } from 'src/apollo/selectors';
import { useEmployeeIdentity } from 'src/apollo/utils';
import GridTable from 'src/components/gridTable';
import { EmptyCell } from 'src/components/table';
import CatalogLoadingIndicator from 'src/components/table/cells/loadingIndicators/catalogLoadingIndicator';
import CountryLoadingIndicator from 'src/components/table/cells/loadingIndicators/countryLoadingIndicator';
import EntityLoadingIndicator from 'src/components/table/cells/loadingIndicators/entityLoadingIndicator';
import PillLoadingIndicator from 'src/components/table/cells/loadingIndicators/pillLoadingIndicator';
import SingleLineLoadingIndicator from 'src/components/table/cells/loadingIndicators/singleLineLoadingIndicator';
import { SortDirection } from 'src/components/table/types';
import {
    PAGINATED_LIMIT_OPTIONS,
    LOADING_INDICATOR_RIGHT_ALIGNMENT_CLASSNAME,
    TABLE_RESULTS_COMMON_CLASSNAME,
} from 'src/pages/searchES/constants';
import { SEARCH_ARTIST_SORTING_FIELDS_MAP } from 'src/pages/searchES/pages/artistResults/constants';

export const CLASSNAME = 'ArtistResultsGridTable';
export const TEST_ID = CLASSNAME;

export interface Props {
    loading?: boolean;
    data?: ParticipantSearchResultItem[];
    totalCount?: number;
    sortBy: GridTableSortBy;
    onSort: (name: string, direction: SortDirection) => void;
    paginated?: boolean;
    page?: number;
    pageSize?: number;
    onPageChange?: (page: number) => void;
    onPageSizeChange?: (size: number) => void;
}

const defaultColumnDefs = {
    sortable: true,
};

const ArtistResultsGridTable: FC<Props> = ({
    loading,
    data,
    paginated,
    totalCount,
    sortBy,
    onSort,
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
}) => {
    const { isEmployee } = useEmployeeIdentity();
    const isNoData = !loading && !data?.length;

    return (
        <GridTable
            name={CLASSNAME}
            className={cx(CLASSNAME, TABLE_RESULTS_COMMON_CLASSNAME)}
            testId={TEST_ID}
            data={data}
            loading={loading}
            rowKey="id"
            onSort={onSort}
            sortBy={sortBy}
            defaultColumnDefs={defaultColumnDefs}
            columnNameToSortNameMap={SEARCH_ARTIST_SORTING_FIELDS_MAP}
            showUpdateIndicator
            stickyHeader
            totalCount={totalCount}
            paginated={paginated && !isNoData}
            page={page}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            pageSizeOptions={PAGINATED_LIMIT_OPTIONS}
            exportable={false}
            customizable={false}
        >
            <GridTable.Column
                name="inCatalog"
                template="inCatalog"
                minWidth={'36px'}
                LoadingIndicator={CatalogLoadingIndicator}
            />
            <GridTable.Column
                name="artistName"
                template="thumbnailLocalParticipantLink"
                title={formatMessage('catalog.artistName')}
                minWidth="545px"
                maxWidth="2fr"
                sortable={false}
                LoadingIndicator={() => (
                    <EntityLoadingIndicator withLabel={false} />
                )}
            />
            <GridTable.Column
                name="country"
                align={'center'}
                className={`${CLASSNAME}-cell-country`}
                HeaderText={EmptyCell}
                template="countryFlagWithTooltip"
                title={formatMessage('chart.country')}
                minWidth="44px"
                maxWidth="44px"
                category={formatMessage('catalog.metadata')}
                visibility="visible-locked"
                LoadingIndicator={CountryLoadingIndicator}
            />
            <GridTable.Column
                name="labelNames"
                title={formatMessage('account.account')}
                template="accountDetails"
                minWidth="200px"
                maxWidth="200px"
                sortable={false}
                visibility={isEmployee ? 'visible' : 'hidden'}
                LoadingIndicator={PillLoadingIndicator}
            />
            <GridTable.Column
                name="monthlyListeners"
                template="number"
                title={formatMessage('chart.monthlyListeners')}
                minWidth="115px"
                maxWidth="115px"
                LoadingIndicator={() => (
                    <SingleLineLoadingIndicator
                        className={LOADING_INDICATOR_RIGHT_ALIGNMENT_CLASSNAME}
                    />
                )}
            />
            <GridTable.Column
                name="streams7Days"
                title={formatMessage('catalog.streams7')}
                template="number"
                minWidth="115px"
                maxWidth="115px"
                LoadingIndicator={() => (
                    <SingleLineLoadingIndicator
                        className={LOADING_INDICATOR_RIGHT_ALIGNMENT_CLASSNAME}
                    />
                )}
            />
            <GridTable.Column
                name="streams28Days"
                title={formatMessage('catalog.streams28')}
                template="number"
                minWidth="115px"
                maxWidth="115px"
                LoadingIndicator={() => (
                    <SingleLineLoadingIndicator
                        className={LOADING_INDICATOR_RIGHT_ALIGNMENT_CLASSNAME}
                    />
                )}
            />
        </GridTable>
    );
};

export default ArtistResultsGridTable;
