import { nonNullable } from 'src/apollo/utils';
import { TopVideosQuery } from '../definitions/TopVideosQuery';
import { Entity } from './types';
import { toEntity, getPreviousMonthNames } from './utils';
import { youtubeVideoUrl, selectVideoParticipants } from './video';

export interface CatalogVideo {
    video: Entity & {
        channelOwner?: string;
        videoContentType?: string;
    };
    channel?: Entity;
    participants: Entity[];
    labelName: string;
    allTimeViews: number;
    allTimeRevenue: number;
    avgViewDuration: number;
    month1Views?: number;
    month1Change: number | null;
    month2Views?: number;
    month2Change: number | null;
    month3Views?: number;
    month3Change: number | null;
}

export interface CatalogVideoResults {
    totalCount: number;
    items: CatalogVideo[];
    months: string[];
}

export const selectTopVideos = (data: TopVideosQuery): CatalogVideoResults => {
    const { videos } = data.topVideos;
    const items: CatalogVideo[] = videos.map(video => {
        const {
            name,
            videoId,
            channel,
            videoContentType,
            analytics: { metrics },
            globalSoundRecording,
        } = video;

        return {
            video: {
                ...toEntity({
                    name,
                    id: videoId,
                    imageUrl: youtubeVideoUrl(videoId),
                }),
                channelOwner: nonNullable(channel?.channelOwner),
                videoContentType: nonNullable(videoContentType),
            },
            channel: channel
                ? toEntity({
                      id: channel.channelId,
                      name: channel.name,
                  })
                : undefined,
            labelName: channel?.vendor?.name ?? '',
            participants: selectVideoParticipants(
                globalSoundRecording ? [globalSoundRecording] : []
            ),
            allTimeViews: metrics?.views ?? 0,
            allTimeRevenue: metrics?.estimatedGrossRevenue ?? 0,
            avgViewDuration: metrics?.averageViewDurationSeconds ?? 0,
            month1Views: metrics?.growthPeriods[0].value || undefined,
            month1Change: metrics?.growthPeriods[0].growthPercentage ?? 0,
            month2Views: metrics?.growthPeriods[1].value || undefined,
            month2Change: metrics?.growthPeriods[1].growthPercentage ?? 0,
            month3Views: metrics?.growthPeriods[2].value || undefined,
            month3Change: metrics?.growthPeriods[2].growthPercentage ?? 0,
        };
    });

    const lastAvailableDate = videos[0]?.analytics.metrics?.lastAvailableDate;

    return {
        totalCount: data.topVideos.totalCount,
        items,
        months: getPreviousMonthNames(lastAvailableDate),
    };
};
