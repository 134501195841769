import {
    COUNTRIES,
    PARTICIPANT,
    TRACK,
    PROJECT,
    IMPRINT,
    SUBACCOUNT,
    PRODUCT,
    SOURCE_OF_STREAMS,
    STORE,
} from 'src/constants/metrics';
import { HeaderTab } from './types';

export const CLASSNAME = 'PerformanceOverTime';
export const CLASSNAME_CHART = `${CLASSNAME}-chart`;
export const CLASSNAME_LOADER = `${CLASSNAME}-loader`;
export const CLASSNAME_TABLE = `${CLASSNAME}-table`;
export const CLASSNAME_TOOLBAR = `${CLASSNAME}-toolbar`;
export const TESTID_CHECKBOX = 'Checkbox';
export const TESTID_CHECKBOX_ALL = 'CheckboxAll';

export const PARAM_POT_IDS = 'potIds';
export const PARAM_POT_METRIC = 'potMetric';
export const PARAM_POT_SOURCES = 'sources';
export const PARAM_POT_DIMENSION = 'potDimension';
export const PARAM_POT_SUBSCRIPTION = 'potSubscription';
export const PARAM_POT_GRAPH = 'potGraphType';
export const PARAM_POT_VERSION = 'potVersion';

export const FILTER_POT_ORDER_BY = 'potOrderBy';
export const FILTER_POT_ORDER_DIR = 'potOrderDir';

export const ACTIONS_TAB = 'actions';
export const ENTITY = 'entity';

export const ENTITIES = [PARTICIPANT, TRACK, PROJECT, IMPRINT, SUBACCOUNT];

export const TOTAL_ROW_NAME = 'TOTAL_ROW';

export const TERM_SOS_TAB = 'song.bySources';
export const TERM_STORE_TAB = 'song.byStores';
export const TERM_PRODUCTS_TAB = 'song.byProducts';
export const TERM_COUNTRY_TAB = 'song.byCountries';
export const TERM_ENTITY_TAB = 'song.byEntities';

export const HEADER_TABS: HeaderTab[] = [
    { id: SOURCE_OF_STREAMS, term: TERM_SOS_TAB },
    { id: COUNTRIES, term: TERM_COUNTRY_TAB },
    { id: STORE, term: TERM_STORE_TAB },
    { id: PRODUCT, term: TERM_PRODUCTS_TAB },
    { id: ENTITY, term: TERM_ENTITY_TAB },
];

export const PARAM_HIDDEN_SERIES = 'hiddenSeries';
