import React from 'react';
import { SkeletonLoader } from '@theorchard/suite-components';
import cx from 'classnames';
import { LoadingIndicatorCommonProps } from 'src/components/table/cells/loadingIndicators/loadingIndicators';

const CLASSNAME = 'singleLineLoadingIndicator';
const ALIGN_RIGHT_CLASSNAME = 'alignRight';

interface Props extends LoadingIndicatorCommonProps {
    alignRight?: boolean;
}
const SingleLineLoadingIndicator: React.FC<Props> = ({
    className,
    alignRight = false,
}) => {
    return (
        <div
            className={cx(CLASSNAME, className, {
                [ALIGN_RIGHT_CLASSNAME]: alignRight,
            })}
        >
            <SkeletonLoader className={`${CLASSNAME}__skeleton`} />
        </div>
    );
};

export default SingleLineLoadingIndicator;
