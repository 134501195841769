import { ApolloError, useQuery } from '@apollo/client';
import {
    GlobalProductSearch,
    GlobalProductSearchVariables,
} from 'src/apollo/definitions/GlobalProductSearch';
import {
    selectProductSearch,
    ProductSearchResult,
} from 'src/apollo/selectors/product';
import globalProductSearch from './globalProductSearch.gql';

export interface ProductSearchQueryResult {
    loading: boolean;
    error?: ApolloError;
    data?: ProductSearchResult;
}

export const useProductSearchQuery = ({
    variables,
    skip,
}: {
    variables: GlobalProductSearchVariables;
    skip?: boolean;
}): ProductSearchQueryResult => {
    const { loading, error, data } = useQuery<
        GlobalProductSearch,
        GlobalProductSearchVariables
    >(globalProductSearch, {
        variables,
        skip,
    });

    return {
        loading,
        data: data && selectProductSearch(data),
        error,
    };
};
