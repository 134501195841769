import {
    ApolloError,
    useLazyQuery,
    useQuery,
    QueryLazyOptions,
} from '@apollo/client';
import {
    VideoSearch,
    VideoSearchVariables,
} from '../../definitions/VideoSearch';
import { selectVideoSearchResults, VideoCatalogSearch } from '../../selectors';
import searchVideoQuery from './videoSearch.gql';

export interface VideoSearchQueryResult {
    loading: boolean;
    error?: ApolloError;
    data?: VideoCatalogSearch;
}

export type VideoSearchLazyQueryResult = [
    (variables: QueryLazyOptions<VideoSearchVariables>) => void,
    VideoSearchQueryResult
];

export const useLazyVideoSearch = (): VideoSearchLazyQueryResult => {
    const [doVideoSearch, { data, loading, error }] = useLazyQuery<
        VideoSearch,
        VideoSearchVariables
    >(searchVideoQuery);

    return [
        doVideoSearch,
        {
            data: data && selectVideoSearchResults(data.videoCatalogSearchV2),
            loading,
            error,
        },
    ];
};

export const useVideoSearchQuery = ({
    variables,
    skip,
}: {
    variables: VideoSearchVariables;
    skip?: boolean;
}): VideoSearchQueryResult => {
    const { loading, error, data } = useQuery<
        VideoSearch,
        VideoSearchVariables
    >(searchVideoQuery, {
        variables,
        skip,
    });

    return {
        loading,
        data: data && selectVideoSearchResults(data.videoCatalogSearchV2),
        error,
    };
};
