import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import ArtistRecentSearched from 'src/pages/searchES/components/recentlyViewedItems/artistRecentSearched';
import ChannelRecentSearched from 'src/pages/searchES/components/recentlyViewedItems/channelRecentSearched';
import ProductRecentSearched from 'src/pages/searchES/components/recentlyViewedItems/productRecentSearched';
import SongRecentSearched from 'src/pages/searchES/components/recentlyViewedItems/songRecentSearched';
import VideoRecentSearched from 'src/pages/searchES/components/recentlyViewedItems/videoRecentSearched';

GridTable.defineColumnTemplate('entityWithLogo', {
    align: 'left',
    Cell: ({ data }) => {
        if ('upc' in data) return <ProductRecentSearched item={data} />;

        if ('channelId' in data) return <ChannelRecentSearched item={data} />;

        if ('id' in data && 'monthlyListeners' in data)
            return <ArtistRecentSearched item={data} />;

        if ('isrc' in data && !('videoId' in data))
            return <SongRecentSearched item={data} />;

        if ('videoId' in data) return <VideoRecentSearched item={data} />;

        return null;
    },
});
