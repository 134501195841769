import { useQuery } from '@apollo/client';
import {
    RecentlyVisitedItems,
    RecentlyVisitedItemsVariables,
} from '../definitions/RecentlyVisitedItems';
import { selectRecentlyViewedItems } from '../selectors';
import recentlyViewedQuery from './recentlyViewed.gql';

export const useRecentlyViewedItemsQuery = (
    variables: RecentlyVisitedItemsVariables
) => {
    const { data, loading, error } = useQuery<
        RecentlyVisitedItems,
        RecentlyVisitedItemsVariables
    >(recentlyViewedQuery, { variables });

    return {
        data: selectRecentlyViewedItems(data),
        loading,
        error,
    };
};
