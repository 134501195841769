import React, { FC } from 'react';
import { GridTable, GridTableCellProps } from '@theorchard/suite-components';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_SONG } from 'src/constants';
import { TEST_ID as songSearchAllResultsId } from 'src/pages/searchES/pages/allResults/songs/songResultsGridTable';
import { TEST_ID as songSearchResultsId } from 'src/pages/searchES/pages/songResults/songResultsGridTable';
import { SongLinkProps } from './types';

const SongLinkCell: FC<GridTableCellProps<SongLinkProps>> = ({
    data: { isrc, songName, name },
    context: {
        props: { testId },
    },
}) => {
    const shouldLogPageVisit =
        testId === songSearchResultsId || testId === songSearchAllResultsId;

    return (
        <TruncatedText
            value={songName ?? name}
            useLink={Boolean(isrc)}
            shouldLogPageVisit={shouldLogPageVisit}
            route={ROUTE_SONG}
            params={{ isrc }}
        />
    );
};

GridTable.defineColumnTemplate('songLink', {
    align: 'left',
    Cell: SongLinkCell,
});

export default SongLinkCell;
