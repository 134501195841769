import React, { FC } from 'react';
import { ChannelSearchResultItem } from 'src/apollo/selectors';
import { ROUTE_CHANNEL } from 'src/constants';
import RecentSearchedItem from 'src/pages/searchES/components/recentlyViewedItems/recentSearchedItem';
import { TERM_YOUTUBE_CHANNEL } from 'src/pages/searchES/constants';
import { createHref } from 'src/utils/route';

const ChannelRecentSearched: FC<{ item: ChannelSearchResultItem }> = ({
    item: { name, channelId, thumbnailUrl },
}) => {
    return (
        <RecentSearchedItem
            title={name ?? ''}
            type={TERM_YOUTUBE_CHANNEL}
            url={createHref(ROUTE_CHANNEL, { id: channelId })}
            imageUrl={thumbnailUrl}
            coverShape="round"
            coverWidth={'30'}
        />
    );
};

export default ChannelRecentSearched;
