import { uniqBy } from 'lodash';
import { ChannelSearch_channelCatalogSearchV2 } from 'src/apollo/definitions/ChannelSearch';
import { Entity } from 'src/apollo/selectors/types';
import { selectAccounts, toEntity } from 'src/apollo/selectors/utils';
import { filterData } from 'src/apollo/utils';
import { Account } from 'src/components/accountsDetailsPopup';
import { EMPTY_CHAR } from 'src/constants';
import { ChannelNameQuery } from '../definitions/ChannelNameQuery';
import { ChannelQuery } from '../definitions/ChannelQuery';
import { StarredChannelsQuery } from '../definitions/StarredChannelsQuery';

export interface ChannelSearchResultItem {
    name?: string;
    channelId: string;
    subscriberCount?: number;
    thumbnailUrl?: string;
    isPartOfCatalog?: boolean;
    labelName?: string;
    accounts?: Account[];
}

export interface ChannelSearchResult {
    totalCount: number;
    items: ChannelSearchResultItem[];
}

export const selectChannelSearchResults = (
    data: ChannelSearch_channelCatalogSearchV2
): ChannelSearchResult => {
    const { items, totalCount } = data;

    return {
        totalCount,
        items: uniqBy(filterData(items), c => c.channelId).map(c => {
            const vendor = [c?.vendor];
            const accounts = selectAccounts(
                uniqBy(vendor as [], 'id.vendorId'),
                null
            );

            return {
                name: c.name ?? undefined,
                channelId: c.channelId,
                subscriberCount: c.subscriberCount ?? undefined,
                thumbnailUrl: c.thumbnailUrl ?? undefined,
                isPartOfCatalog: c.isPartOfCatalog,
                labelName: c.vendor?.name,
                accounts,
            };
        }) as ChannelSearchResultItem[],
    };
};

export interface ChannelMetadata {
    id: string;
    date: string;
    name: string;
    description: string;
    imageUrl: string;
    subscribers: number;
    labelName?: string;
    isPartOfCatalog: boolean;
    globalParticipant?: Entity;
    following: boolean;
}

export const selectChannel = (
    data: ChannelQuery | undefined
): ChannelMetadata | null => {
    const channel = data?.channel;
    if (!channel?.name) return null;

    return {
        id: channel.channelId,
        name: channel.name,
        date: channel.publishedAt?.formatted ?? '2016-02-01',
        description: channel.description ?? '',
        imageUrl: channel.thumbnailUrl ?? '',
        subscribers: channel.subscriberCount ?? 0,
        labelName: channel.vendor?.name ?? EMPTY_CHAR,
        isPartOfCatalog: channel.isPartOfCatalog,
        globalParticipant:
            (channel.globalParticipant &&
                toEntity(channel.globalParticipant)) ??
            undefined,
        following: channel.following,
    };
};

export const selectChannelName = (data: ChannelNameQuery | undefined) => {
    const channel = data?.channel;
    if (!channel?.name) return null;

    return {
        id: channel.channelId,
        name: channel.name,
    };
};

export interface StarredChannel {
    channel: Entity;
    isPartOfCatalog: boolean;
    subscribers: number | null;
    allTimeViews: number | null;
    following: boolean;
    dateFollowed: string | null;
    globalParticipant: Entity | null;
    labelName: string | null;
}

export const selectStarredChannels = (
    data: StarredChannelsQuery
): StarredChannel[] =>
    data.starredChannels.map(
        ({
            channelId,
            name,
            thumbnailUrl,
            isPartOfCatalog,
            subscriberCount,
            globalParticipant,
            vendor,
            analytics,
            following,
            dateFollowed,
        }) => ({
            channel: toEntity({
                id: channelId,
                name,
                imageUrl: thumbnailUrl,
            }),
            isPartOfCatalog,
            subscribers: subscriberCount ?? null,
            globalParticipant:
                (globalParticipant && toEntity(globalParticipant)) ?? null,
            allTimeViews: analytics.allTime.data.views ?? null,
            following,
            dateFollowed: dateFollowed ?? null,
            labelName: vendor?.name ?? null,
        })
    );
