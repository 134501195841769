import React, { VFC } from 'react';
import { createHref } from '@theorchard/suite-frontend';
import { Redirect } from 'react-router-dom';
import { useEmployeeIdentity } from 'src/apollo/utils';
import { ROUTE_CATALOG_STRICT, ROUTE_SEARCH } from 'src/constants';

const HomeRedirect: VFC = () => {
    const { isSmeEmployee } = useEmployeeIdentity();

    if (isSmeEmployee) return <Redirect to={createHref(ROUTE_SEARCH)} />;

    return <Redirect to={createHref(ROUTE_CATALOG_STRICT)} />;
};

export default HomeRedirect;
