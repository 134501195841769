import { useApolloClient } from '@apollo/client';
import { flattenError } from '@theorchard/suite-frontend';
import {
    PlaylistType,
    selectPlaylistPlacementBreakdownByCountry,
} from 'src/apollo/selectors/playlist';
import {
    PlaylistPlacementBreakdownByCountry as QueryData,
    PlaylistPlacementBreakdownByCountryVariables as QueryVars,
} from '../../definitions/PlaylistPlacementBreakdownByCountry';
import query from './playlistPlacementBreakdownByCountry.gql';

interface PlaylistStorefrontsResult {
    loading: boolean;
    error?: Error;
    data?: PlaylistType[];
}

interface PlaylistStorefrontsQueryType {
    (variables: QueryVars): PromiseLike<PlaylistStorefrontsResult>;
}

export const usePlaylistStorefrontsQuery = (
    dateFilter?: string
): PlaylistStorefrontsQueryType => {
    const client = useApolloClient();

    const fetchStorefronts = async (
        variables: QueryVars
    ): Promise<PlaylistStorefrontsResult> =>
        await client
            .query<QueryData, QueryVars>({ query, variables })
            .then(async ({ data }) => {
                const selectedPlacements =
                    selectPlaylistPlacementBreakdownByCountry(
                        data,
                        dateFilter
                    ).map(placements => ({
                        isrc: variables.isrc,
                        ...placements,
                    }));

                return await Promise.resolve({
                    data: data && selectedPlacements,
                    loading: false,
                });
            })
            .catch(
                async error =>
                    await Promise.resolve({
                        loading: false,
                        error: flattenError(error),
                    })
            );

    return fetchStorefronts;
};
