import { flatMap, groupBy, isNull, keyBy, map, orderBy, uniqBy } from 'lodash';
import {
    ChartRankingsForVideoByDefinitionQuery_chartsV2ByDefinition,
    ChartRankingsForVideoByDefinitionQuery_chartsV2ByDefinition_date_placements_placements,
} from 'src/apollo/definitions/ChartRankingsForVideoByDefinitionQuery';
import { assertValue, filterData, nonNullable } from 'src/apollo/utils';
import { BrandItem } from 'src/components/brandIconStack/types';
import { EMPTY_CHAR } from 'src/constants';
import {
    ChartDefinition,
    getDefinition,
    getDefinitionKey,
} from 'src/constants/charts';
import {
    COUNTRY_GLOBAL,
    COUNTRY_NAME_GLOBAL,
    COUNTRY_UNKNOWN,
    getCountryName,
} from 'src/constants/countries';
import { STORE_AMAZON, STORE_ITUNES } from 'src/constants/stores';
import { getLocalizedName, orderCountries } from 'src/utils/countries';
import { ChartRankingsBrandsV2WithGSRQuery_chartRankingsV2 } from '../definitions/ChartRankingsBrandsV2WithGSRQuery';
import { ChartRankingsBrandsV2WithProductQuery_chartRankingsV2 } from '../definitions/ChartRankingsBrandsV2WithProductQuery';
import { ChartRankingsBrandsV2WithVideoQuery_chartRankingsV2 } from '../definitions/ChartRankingsBrandsV2WithVideoQuery';
import { ChartsV2Query_chartsV2 as ChartQueryChartV2 } from '../definitions/ChartsV2Query';
import { TrackOfferType } from '../definitions/globalTypes';
import { Entity } from './types';
import { toEntity } from './utils';

export interface ChartRanking {
    position: number;
    isrc?: string;
    numTracks?: number | null;
    change?: number | null;
    streams?: number | null;
    totalStreams?: number | null;
    totalShazams?: number | null;
    participants: Entity[];
    songName: string;
    imprint: string;
    key: string;
    songId: string;
    trackId?: string;
    releaseDate?: string;
    peakPosition?: number;
    peakTimestamp?: string;
    daysOnChart: number;
    offerTypes?: TrackOfferType[];
    isPartOfCatalog: boolean | null;
    spotifyId?: string;
    views?: number | null;
    channelId?: string | null;
    channelName?: string | null;
    productName?: string;
    upc?: string;
    brands?: BrandItem[] | null;
    videoId?: string;
}

export interface YouTubeChartRanking {
    position: number;
    change?: number | string;
    brands: BrandItem[];
    songName?: string;
    videoId?: string;
    channelName: string | null;
    channelId: string | null;
    views?: number | null;
    peakPosition?: number;
    peakTimestamp?: string;
    daysOnChart: number;
}

export interface ChartRankingByDefinition extends YouTubeChartRanking {
    country: string | null;
}

export const streamingOfferTypes = [
    TrackOfferType.ALL,
    TrackOfferType.STREAM_ONLY,
    TrackOfferType.ALBUM_DOWNLOAD_STREAM,
    TrackOfferType.TRACK_DOWNLOAD_STREAM,
];

export type UnionedChartRankingsBrandsV2 =
    ChartRankingsBrandsV2WithGSRQuery_chartRankingsV2 &
        ChartRankingsBrandsV2WithProductQuery_chartRankingsV2 &
        ChartRankingsBrandsV2WithVideoQuery_chartRankingsV2;

const getPreferredCountryForPlatform = (
    platform: string,
    group: ChartQueryChartV2[]
) => {
    switch (platform) {
        case STORE_ITUNES:
        case STORE_AMAZON:
            return group.find(
                (row: Partial<ChartQueryChartV2>) => row.country === 'US'
            )
                ? 'US'
                : COUNTRY_GLOBAL;

        default:
            return COUNTRY_GLOBAL;
    }
};

export const selectChartV2Definitions = (data: ChartQueryChartV2[]) =>
    orderBy(
        map(groupBy(data, getDefinitionKey), group => {
            const [{ id, ...first }] = group;
            const definition = getDefinition(first);
            const preferredCountry = getPreferredCountryForPlatform(
                definition.platform,
                group
            );

            return {
                ...definition,
                countries: orderCountries(
                    group.map(row => ({
                        id: row.id,
                        value: row.country,
                        label: getLocalizedName(row.country || COUNTRY_UNKNOWN),
                        lastAvailableTimestamp: nonNullable(
                            row.lastAvailableTimestamp
                        ),
                    })),
                    preferredCountry
                ),
            } as ChartDefinition;
        }),
        'label',
        'desc'
    );

export const selectChartsV2 = (data: ChartQueryChartV2[]) => {
    const charts = selectChartV2Definitions(data);
    const definitionsByKey = keyBy(charts, 'key');
    const chartsById = keyBy(
        data.map(chart => ({
            id: assertValue(chart, 'id'),
            modifiedAt: nonNullable(chart.modifiedAt),
            lastAvailableTimestamp: nonNullable(chart.lastAvailableTimestamp),
            platform: nonNullable(chart.platform),
            type: nonNullable(chart.type),
            genre: nonNullable(chart.genre),
            frequency: nonNullable(chart.frequency),
            target: nonNullable(chart.target),
            country: nonNullable(chart.country),
            definition: definitionsByKey[getDefinitionKey(chart)],
        })),
        'id'
    );

    return { charts, chartsById };
};

const getUniqBrandsV2 = (chartRanking: UnionedChartRankingsBrandsV2) => {
    const brandItems = filterData(chartRanking.brands).map<BrandItem>(
        ({ name, displayName: label }) => ({ name, label })
    );

    return uniqBy(brandItems, 'name');
};

export const selectChartsByDefinition = (
    data?: ChartRankingsForVideoByDefinitionQuery_chartsV2ByDefinition[]
): ChartRankingByDefinition[] => {
    if (!data) return [];

    const mappedData = flatMap(
        data,
        (
            entry: ChartRankingsForVideoByDefinitionQuery_chartsV2ByDefinition
        ): ChartRankingByDefinition[] => {
            const chartRankings = selectYouTubeChartRanking(
                entry.date?.placements.placements ?? []
            );
            const countryCode = entry.country;
            const countryName =
                countryCode === COUNTRY_GLOBAL
                    ? COUNTRY_NAME_GLOBAL
                    : getCountryName(countryCode ?? '');
            return chartRankings.map(chartRanking => ({
                ...chartRanking,
                country: countryName,
            }));
        }
    );

    // order chartRankings by country asc, but Global should be first
    return orderBy(
        mappedData,
        [
            chartRanking =>
                chartRanking.country === COUNTRY_NAME_GLOBAL
                    ? '000_global'
                    : chartRanking.country,
            'country',
        ],
        'asc'
    );
};

export const selectYouTubeChartRanking = (
    data: ChartRankingsForVideoByDefinitionQuery_chartsV2ByDefinition_date_placements_placements[]
) => {
    if (!data) return [];

    return data.map(entry => {
        const offerTypes = flatMap(
            entry.isrc?.globalSoundRecording?.labelSoundRecordings,
            ({ tracks }) => filterData(tracks.map(track => track.offerType))
        );

        const isPartOfCatalog = entry.isPartOfCatalog;
        const isStreamingOfferType = offerTypes
            ? offerTypes.some(offerType =>
                  streamingOfferTypes.includes(offerType)
              )
            : false;

        const uniqBrands =
            isPartOfCatalog && isStreamingOfferType
                ? getUniqBrandsV2(entry as UnionedChartRankingsBrandsV2)
                : [];

        return {
            position: entry.position,
            change: isNull(entry.positionChange) ? 'New' : entry.positionChange,
            brands: uniqBrands,
            songName: entry.publicSoundRecording?.trackName ?? undefined,
            videoId: entry.videoId ?? undefined,
            channelName: entry.channelName,
            channelId: entry.channelId,
            views: entry.views,
            peakPosition: entry.peakPosition ?? undefined,
            peakTimestamp: entry.peakTimestamp ?? undefined,
            daysOnChart: entry.daysOnChart ?? 0,
        };
    });
};

export const selectChartRankingsBrandsV2 = (
    chartRankings?: UnionedChartRankingsBrandsV2[]
): ChartRanking[] => {
    if (!chartRankings) return [];

    return chartRankings.map((entry, index) => {
        const offerTypes = entry.isrc?.globalSoundRecording
            ?.labelSoundRecordings
            ? flatMap(
                  entry.isrc?.globalSoundRecording?.labelSoundRecordings,
                  ({ tracks }) =>
                      filterData(tracks.map(track => track.offerType))
              )
            : entry.globalProduct?.catalogProduct.tracks.map(
                  track => track.offerType
              );

        const artistNames = entry.globalProduct
            ? entry.globalProduct.catalogProduct.artists
                  .filter(
                      artist =>
                          artist?.artistType === 'primary_artist' &&
                          artist?.artistName
                  )
                  .map(artist => artist?.artistName)
                  .join(', ')
            : entry.artistNames;

        const fallbackParticipants = [
            toEntity({
                id: EMPTY_CHAR,
                name: artistNames,
                imageUrl: null,
            }),
        ];

        const globalParticipants =
            entry.isrc?.globalSoundRecording?.globalParticipants;

        const checkParticipants =
            globalParticipants && !!globalParticipants.length;

        const participants = checkParticipants
            ? filterData(
                  globalParticipants?.map(
                      globalParticipant =>
                          globalParticipant &&
                          toEntity({
                              id: globalParticipant.id ?? EMPTY_CHAR,
                              name: globalParticipant.name,
                              imageUrl: null,
                          })
                  )
              )
            : fallbackParticipants;

        const isrc =
            entry.isrc?.isrc ?? entry?.isrc?.globalSoundRecording?.isrc;
        const songName = entry.videoId
            ? entry.publicSoundRecording?.trackName || EMPTY_CHAR
            : entry.isrc?.globalSoundRecording?.name ||
              entry.publicSoundRecording?.name ||
              EMPTY_CHAR;
        const productName =
            entry.globalProduct?.catalogProduct?.productName ||
            entry.publicProduct?.name ||
            EMPTY_CHAR;
        const imprint =
            entry.isrc?.globalSoundRecording?.imprint ??
            entry.globalProduct?.catalogProduct?.labelName ??
            entry.publicProduct?.label?.name ??
            EMPTY_CHAR;
        const releaseDate =
            entry.isrc?.globalSoundRecording?.releaseDateV2 ??
            entry.globalProduct?.catalogProduct?.releaseDate ??
            entry.publicProduct?.releaseDate?.formatted ??
            undefined;
        const spotifyTracks =
            entry.isrc?.globalSoundRecording?.soundRecording?.tracks;
        const spotifyId =
            spotifyTracks?.find(entity => entity.spotifyId)?.spotifyId ??
            undefined;

        const isPartOfCatalog = entry.isPartOfCatalog;
        const isStreamingOfferType = offerTypes
            ? offerTypes.some(offerType =>
                  streamingOfferTypes.includes(offerType)
              )
            : false;

        const uniqBrands =
            isPartOfCatalog && isStreamingOfferType
                ? getUniqBrandsV2(entry)
                : [];

        return {
            position: entry.position,
            isrc,
            upc: nonNullable(entry.upc),
            numTracks: entry.numTracks,
            change: entry.positionChange,
            streams: entry.streams,
            totalStreams: entry.totalStreams,
            totalShazams: entry.totalShazams,
            participants,
            songName,
            productName,
            imprint,
            key: `${entry.position}-${entry.trackId}-${index}`,
            songId:
                (entry.publicSoundRecordingId || entry.publicProduct?.id) ??
                EMPTY_CHAR,
            trackId: entry.trackId ?? undefined,
            releaseDate,
            peakPosition: entry.peakPosition ?? undefined,
            peakTimestamp: entry.peakTimestamp ?? undefined,
            daysOnChart: entry.daysOnChart ?? 0,
            videoId: entry.videoId ?? undefined,
            videoName: entry.publicSoundRecording?.name ?? undefined,
            offerTypes,
            isPartOfCatalog,
            spotifyId,
            views: entry.views,
            channelName: entry.channelName,
            channelId: entry.channelId,
            publicProduct: entry.publicProduct,
            brands: uniqBrands,
        };
    });
};
