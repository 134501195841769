import React, { FC } from 'react';
import { concatNames, ProductSearchResultItem } from 'src/apollo/selectors';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_PRODUCT } from 'src/constants';
import RecentSearchedItem from 'src/pages/searchES/components/recentlyViewedItems/recentSearchedItem';
import { TERM_PRODUCT } from 'src/pages/searchES/constants';
import { createHref } from 'src/utils/route';

const ProductRecentSearched: FC<{ item: ProductSearchResultItem }> = ({
    item: { productName, upc, imageLocation, participants },
}) => {
    const areParticipantsAvailable = participants?.length !== 0;

    return (
        <RecentSearchedItem
            title={productName}
            type={TERM_PRODUCT}
            url={createHref(ROUTE_PRODUCT, { upc })}
            imageUrl={imageLocation}
            coverShape={'square'}
            coverWidth={'30'}
        >
            {areParticipantsAvailable && (
                <>
                    &nbsp;&#8209;&nbsp;
                    <TruncatedText value={concatNames(participants)} />
                </>
            )}
            {upc && (
                <>
                    &nbsp;&#8209;&nbsp;
                    <span className="ml-auto">{upc}</span>
                </>
            )}
        </RecentSearchedItem>
    );
};

export default ProductRecentSearched;
