import React from 'react';
import { GridTableSortBy } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import cx from 'classnames';
import { SoundRecordingSearchResultItem } from 'src/apollo/selectors';
import { useEmployeeIdentity } from 'src/apollo/utils';
import GridTable from 'src/components/gridTable';
import CatalogLoadingIndicator from 'src/components/table/cells/loadingIndicators/catalogLoadingIndicator';
import EntityLoadingIndicator from 'src/components/table/cells/loadingIndicators/entityLoadingIndicator';
import PillLoadingIndicator from 'src/components/table/cells/loadingIndicators/pillLoadingIndicator';
import SingleLineLoadingIndicator from 'src/components/table/cells/loadingIndicators/singleLineLoadingIndicator';
import { SortDirection } from 'src/components/table/types';
import {
    LOADING_INDICATOR_RIGHT_ALIGNMENT_CLASSNAME,
    PAGINATED_LIMIT_OPTIONS,
    TABLE_RESULTS_COMMON_CLASSNAME,
} from 'src/pages/searchES/constants';
import { TableCommonProps } from 'src/pages/searchES/pages/table';
import { SEARCH_SONG_SORTING_FIELDS_MAP } from './constants';

export const CLASSNAME = 'SongResultsGridTable';

export const CLASSNAME_V2 = 'SongResultsGridTableV2';

export const TEST_ID = CLASSNAME;

export interface Props extends TableCommonProps {
    data?: SoundRecordingSearchResultItem[];
    sortBy: GridTableSortBy;
    onSort: (name: string, direction: SortDirection) => void;
}

const defaultColumnDefs = {
    sortable: true,
};

const SongResultsGridTable: React.FC<Props> = ({
    loading,
    data,
    sortBy,
    paginated,
    totalCount,
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
    onSort,
}) => {
    const { isEmployee } = useEmployeeIdentity();
    const isNoData = !loading && !data?.length;
    const handledData = loading ? [] : data;

    return (
        <GridTable
            name={CLASSNAME}
            className={cx(
                CLASSNAME,
                CLASSNAME_V2,
                TABLE_RESULTS_COMMON_CLASSNAME
            )}
            testId={TEST_ID}
            data={handledData}
            loading={loading}
            rowKey="isrc"
            onSort={onSort}
            sortBy={sortBy}
            defaultColumnDefs={defaultColumnDefs}
            columnNameToSortNameMap={SEARCH_SONG_SORTING_FIELDS_MAP}
            totalCount={totalCount}
            page={page}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            paginated={paginated && !isNoData}
            pageSizeOptions={PAGINATED_LIMIT_OPTIONS}
            showUpdateIndicator
            stickyHeader
            exportable={false}
            customizable={false}
        >
            <GridTable.Column
                name="inCatalog"
                template="inCatalog"
                minWidth={'36px'}
                LoadingIndicator={CatalogLoadingIndicator}
            />
            <GridTable.Column
                name="name"
                template="thumbnailSongLink"
                title={formatMessage('catalog.songName')}
                minWidth="319px"
                maxWidth="4fr"
                sortable={false}
                LoadingIndicator={() => (
                    <EntityLoadingIndicator withLabel={false} />
                )}
            />
            <GridTable.Column
                name="artistName"
                template="participantLinkWithCounter"
                title={formatMessage('catalog.artistName')}
                minWidth="200px"
                maxWidth="3fr"
                LoadingIndicator={SingleLineLoadingIndicator}
            />
            <GridTable.Column
                name="labelNames"
                title={formatMessage('account.account')}
                template="accountDetails"
                minWidth="140px"
                maxWidth="2fr"
                sortable={false}
                LoadingIndicator={PillLoadingIndicator}
                visibility={isEmployee ? 'visible' : 'hidden'}
            />
            <GridTable.Column
                name="isrc"
                template="truncatedText"
                title={formatMessage('track.metadata.isrc')}
                minWidth={'130px'}
                maxWidth="max-content"
                sortable={false}
                LoadingIndicator={SingleLineLoadingIndicator}
            />
            <GridTable.Column
                name="releaseDate"
                template="date"
                title={formatMessage('song.release')}
                minWidth={'115px'}
                maxWidth="max-content"
                LoadingIndicator={() => (
                    <SingleLineLoadingIndicator
                        className={LOADING_INDICATOR_RIGHT_ALIGNMENT_CLASSNAME}
                    />
                )}
            />
            <GridTable.Column
                name="streams7Days"
                title={formatMessage('catalog.streams7')}
                template="number"
                minWidth={'115px'}
                LoadingIndicator={() => (
                    <SingleLineLoadingIndicator
                        className={LOADING_INDICATOR_RIGHT_ALIGNMENT_CLASSNAME}
                    />
                )}
            />
            <GridTable.Column
                name="streams28Days"
                title={formatMessage('catalog.streams28')}
                template="number"
                minWidth={'115px'}
                LoadingIndicator={() => (
                    <SingleLineLoadingIndicator
                        className={LOADING_INDICATOR_RIGHT_ALIGNMENT_CLASSNAME}
                    />
                )}
            />
        </GridTable>
    );
};

export default SongResultsGridTable;
