import { formatMessage } from '@theorchard/suite-frontend';
import { uniqBy, sortBy, last } from 'lodash';
import { StarredVideoRecordingsQuery } from 'src/apollo/definitions/StarredVideoRecordingsQuery';
import { VideoSearch_videoCatalogSearchV2 } from 'src/apollo/definitions/VideoSearch';
import { filterData, nonNullable } from 'src/apollo/utils';
import { Account } from 'src/components/accountsDetailsPopup';
import { ChannelVideosQuery } from '../definitions/ChannelVideosQuery';
import {
    VideoMetadataQuery,
    VideoMetadataQuery_video_relatedGlobalSoundRecordings_globalParticipants as VideoGP,
} from '../definitions/VideoMetadataQuery';
import { VideoQuery } from '../definitions/VideoQuery';
import { Entity } from './types';
import { selectAccounts, toEntity } from './utils';

const TERM_UNKNOWN_CHANNEL = 'channel.unknown';
export const youtubeVideoUrl = (videoId: string) =>
    `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;

export interface VideoSearchResult {
    name?: string;
    videoId: string;
    channel?: Entity;
    isPartOfCatalog: boolean;
    imageUrl: string;
    labelName?: string | null;
    isrc?: string;
    accounts?: Account[];
}

export interface VideoCatalogSearch {
    totalCount: number;
    items: VideoSearchResult[];
}

export const selectVideoSearchResults = (
    data: VideoSearch_videoCatalogSearchV2
): VideoCatalogSearch => {
    const { items, totalCount } = data;

    return {
        totalCount,
        items: uniqBy(filterData(items), v => v.videoId).map(v => {
            const vendor = [v?.channel?.vendor];
            const accounts = selectAccounts(
                uniqBy(vendor as [], 'id.vendorId'),
                null
            );

            return {
                name: v.name ?? undefined,
                videoId: v.videoId,
                isPartOfCatalog: v.isPartOfCatalog,
                channel: v.channel
                    ? toEntity({
                          name: v.channel.name,
                          id: v.channel.channelId,
                      })
                    : undefined,
                imageUrl: youtubeVideoUrl(v.videoId),
                labelName: v.globalSoundRecording?.imprint ?? null,
                isrc: v.globalSoundRecording?.isrc ?? '',
                accounts,
            };
        }),
    };
};

export interface Video {
    id: string;
    isrc: string;
    name?: string;
    imageUrl: string;
    videoContentType?: string;
    isPartOfCatalog: boolean;
    releaseDate?: string;
}

export interface VideoMetadata {
    id: string;
    isrc: string;
    name?: string;
    releaseDate?: string;
    imageUrl: string;
    channel?: {
        id: string;
        name: string;
        channelOwner?: string;
        subscribers: number;
    };
    participants: Entity[];
    labelName?: string;
    videoContentType?: string;
    isPartOfCatalog: boolean;
    following?: boolean;
}

interface GSR {
    globalParticipants: (VideoGP | null)[] | null;
}

export const selectVideoParticipants = (gsrs: GSR[]) =>
    filterData(gsrs)
        .map(gsr => filterData(gsr.globalParticipants).map(toEntity))
        .reduce(
            (maxParticipants, current) =>
                current.length > maxParticipants.length
                    ? current
                    : maxParticipants,
            []
        );

export const selectVideo = (data?: VideoQuery): Video | null => {
    const video = data?.video;

    if (!video || !video?.name) return null;

    const videoDate =
        video.timePublished?.formatted ?? video.timeUploaded?.formatted;
    const releaseDate = videoDate ? videoDate.slice(0, 10) : '';

    return {
        id: video.videoId,
        name: nonNullable(video.name),
        isrc: video.isrc ?? '',
        releaseDate,
        imageUrl: youtubeVideoUrl(video.videoId),
        isPartOfCatalog: video.isPartOfCatalog,
        videoContentType: nonNullable(video?.videoContentType),
    };
};

export const selectVideoMetadata = (
    data?: VideoMetadataQuery
): VideoMetadata | null => {
    const video = data?.video;

    if (!video || !video?.name) return null;

    const gsr = video.videoGlobalSoundRecording;
    const participants = selectVideoParticipants(
        video.relatedGlobalSoundRecordings
    );
    const videoDate =
        video.timePublished?.formatted ?? video.timeUploaded?.formatted;
    const releaseDate = videoDate ? videoDate.slice(0, 10) : '';

    return {
        id: video.videoId,
        name: nonNullable(video.name),
        isrc: video.isrc ?? '',
        releaseDate,
        imageUrl: youtubeVideoUrl(video.videoId),
        channel: video.channel
            ? {
                  id: video.channel.channelId,
                  name:
                      video.channel.name ?? formatMessage(TERM_UNKNOWN_CHANNEL),
                  subscribers: video.channel.subscriberCount ?? 0,
                  channelOwner: nonNullable(video.channel.channelOwner),
              }
            : undefined,
        participants,
        labelName: nonNullable(gsr?.labelName),
        isPartOfCatalog: video.isPartOfCatalog,
        following: gsr?.following,
        videoContentType: nonNullable(video?.videoContentType),
    };
};

export interface ChannelVideoMetadata {
    id: string;
    name: string;
    releaseDate: string;
    imageUrl: string;
}

export const selectChannelVideos = (
    data?: ChannelVideosQuery
): ChannelVideoMetadata[] => {
    const videos = data?.channel?.videos ?? [];

    return filterData(videos)
        .map(video => ({
            id: video.videoId,
            name: video.name ?? '',
            releaseDate:
                video.timePublished?.formatted ??
                video.timeUploaded?.formatted ??
                '',
            imageUrl: youtubeVideoUrl(video.videoId),
        }))
        .filter(v => v.name && v.releaseDate);
};

interface StarredVideoVideo {
    id: string | null;
    imageUrl: string | null;
    name: string | null;
}

interface StarredVideoChannel {
    id: string | null;
    name: string | null;
}

export interface StarredVideo {
    video: StarredVideoVideo;
    isrc: string;
    participants: Entity[];
    releaseDate: string | null;
    labelName: string | null;
    isPartOfCatalog: boolean | null;
    following: boolean;
    dateFollowed: string;
    streamsAllTime: number | null;
    channel: StarredVideoChannel | null;
}

export const selectStarredVideoRecordings = (
    data: StarredVideoRecordingsQuery
): StarredVideo[] => {
    const filteredData = data.starredVideoRecordings.filter(
        record => record.videos && record.videos.length
    );

    return filteredData.map(
        ({
            isrc,
            globalParticipants,
            releaseDateV2,
            following,
            dateFollowed,
            videos,
        }) => {
            const video = last(sortBy(videos, 'analytics.allTime.data.views'));
            return {
                video: {
                    id: video?.videoId || null,
                    name: video?.name || null,
                    imageUrl: video ? youtubeVideoUrl(video.videoId) : null,
                },
                isrc,
                participants: filterData(globalParticipants).map(toEntity),
                releaseDate: releaseDateV2 ?? null,
                labelName: video?.channel?.vendor?.name ?? null,
                isPartOfCatalog: video?.isPartOfCatalog ?? null,
                following,
                dateFollowed,
                streamsAllTime: video?.analytics?.allTime?.data?.views ?? null,
                channel: video?.channel
                    ? { id: video.channel.channelId, name: video.channel.name }
                    : null,
            };
        }
    );
};
