import React, { FC } from 'react';
import { GridTableCellProps, Tooltip } from '@theorchard/suite-components';
import { formatNumber } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import cx from 'classnames';
import { get, isNull, uniqueId } from 'lodash';
import { EMPTY_CHAR } from 'src/constants';
import { formatPercentage, normalizeNumber } from 'src/utils';

export interface CellProps extends GridTableCellProps<object> {
    percentageProperty: string;
    currentValueProperty: string;
}

const isIncrease = (percentageValue: number | null, change: number | null) => {
    const isPercentageValueIncrease =
        percentageValue === null ? false : percentageValue > 0;
    const isChangeIncrease = change === null ? false : change > 0;

    if (percentageValue !== null)
        return percentageValue === 0
            ? isChangeIncrease
            : isPercentageValueIncrease;

    return isChangeIncrease;
};

const NumberWithPercentageAndArrowCell: FC<CellProps> = ({
    data,
    value,
    column: { definition },
    percentageProperty,
    currentValueProperty,
}) => {
    const percentage: number | null = get(data, percentageProperty);
    const useExistingPercentage: boolean = get(
        definition,
        'data-use-existing-percentage',
        false
    );

    const currentValue = get(data, currentValueProperty);
    const currentNormalizedValue = normalizeNumber(currentValue);
    const normalizedPrevValue = normalizeNumber(value);
    let percentageValue = normalizeNumber(percentage);
    const isNewSong = percentageValue === 0;
    const change =
        currentNormalizedValue !== null && normalizedPrevValue !== null
            ? currentNormalizedValue - normalizedPrevValue
            : null;
    const isChangeDataAbsent = change === 0 && isNewSong;

    if (!isChangeDataAbsent && !useExistingPercentage) {
        percentageValue = isNewSong ? 1 : percentageValue;
    }

    if ((isNull(percentageValue) || isNewSong) && isNull(change)) {
        return <span>{EMPTY_CHAR}</span>;
    }

    let percentageContent = (
        <span className="percentage">
            {!isNull(percentageValue)
                ? `(${formatPercentage(percentageValue)})`
                : '(N/A)'}
        </span>
    );

    if (!isNull(percentageValue) && percentageValue > 9.99) {
        percentageContent = (
            <Tooltip
                id={`NumberWithPercentageAndArrowCell_${percentageValue}_${uniqueId()}`}
                message={formatPercentage(percentageValue)}
            >
                <span className="percentage">{`(${formatNumber(
                    percentageValue * 100
                )}%)`}</span>
            </Tooltip>
        );
    }

    const isPercentageIncreased = isIncrease(percentageValue, change);
    const className = isPercentageIncreased
        ? 'percentageIncreaseWithArrow'
        : 'percentageDecreaseWithArrow';

    return (
        <div className={cx('NumberWithPercentageAndArrowCell', className)}>
            {!isChangeDataAbsent && (
                <GlyphIcon
                    name={isPercentageIncreased ? 'arrowUp' : 'arrowDown'}
                    size={12}
                />
            )}
            <span className="change">
                {isNull(change) ? 'N/A' : formatNumber(change)}
            </span>
            {percentageContent}
        </div>
    );
};

export default NumberWithPercentageAndArrowCell;
