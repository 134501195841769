export function watchClassList(
    element: Element,
    callback: (classList: DOMTokenList) => void
) {
    const observerCallback = function (mutationsList: MutationRecord[]) {
        for (const mutation of mutationsList) {
            if (
                mutation.type === 'attributes' &&
                mutation.attributeName === 'class'
            ) {
                callback(element.classList);
            }
        }
    };

    const observer = new MutationObserver(observerCallback);
    const config = { attributes: true };

    observer.observe(element, config);

    return () => {
        observer.disconnect();
    };
}
