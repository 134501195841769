import { useQuery } from '@apollo/client';
import { CompanyBrands } from 'src/apollo/definitions/CompanyBrands';
import { selectCompany } from 'src/apollo/selectors/product';
import { flattenError } from '../../utils';
import companyBrandsQuery from './companyBrands.gql';

export const useCompanyBrandsQuery = (skip?: boolean) => {
    const { data, loading, error } = useQuery<CompanyBrands>(
        companyBrandsQuery,
        { fetchPolicy: 'cache-first', skip }
    );

    return {
        loading,
        data: selectCompany(data),
        error: error && flattenError(error),
    };
};
