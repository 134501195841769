import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { get } from 'lodash';
import CoverArt from 'src/components/coverArt';
import PlaylistLinkCell from './playlistLinkCell';

GridTable.defineColumnTemplate('thumbnailPlaylist', {
    align: 'left',
    Cell: ({ data, ...rest }) => {
        const {
            column: { definition },
        } = rest;
        return (
            <div className="ThumbnailPlaylistLinkCell CoverArtCell">
                <CoverArt
                    url={data.imageLocation}
                    width={get(definition, ['data-coverArtSize']) || '30'}
                />
                <PlaylistLinkCell data={data} {...rest} />
            </div>
        );
    },
});
