import { useCallback } from 'react';
import {
    ApolloError,
    QueryLazyOptions,
    useLazyQuery,
    useQuery,
} from '@apollo/client';
import {
    ChannelSearch,
    ChannelSearchVariables,
} from 'src/apollo/definitions/ChannelSearch';
import {
    ChannelSearchResult,
    selectChannelSearchResults,
} from 'src/apollo/selectors';
import searchChannelQuery from './channelSearch.gql';

export interface ChannelSearchQueryResult {
    loading: boolean;
    error?: ApolloError;
    data?: ChannelSearchResult;
}

interface ChannelSearchQueryHandler {
    (
        variables: QueryLazyOptions<ChannelSearchVariables>
    ): Promise<ChannelSearchQueryResult>;
}

export type ChannelSearchLazyQueryResult = [
    ChannelSearchQueryHandler,
    ChannelSearchQueryResult
];

export const useLazyChannelSearch = (): ChannelSearchLazyQueryResult => {
    const [doChannelSearch, { data, loading, error }] = useLazyQuery<
        ChannelSearch,
        ChannelSearchVariables
    >(searchChannelQuery);

    const executeSearch = useCallback<ChannelSearchQueryHandler>(
        async args => {
            const { data: searchData } = await doChannelSearch(args);
            return {
                loading: false,
                data:
                    searchData &&
                    selectChannelSearchResults(
                        searchData.channelCatalogSearchV2
                    ),
            };
        },
        [doChannelSearch]
    );

    return [
        executeSearch,
        {
            data:
                data && selectChannelSearchResults(data.channelCatalogSearchV2),
            loading,
            error,
        },
    ];
};

export const useChannelSearchQuery = ({
    variables,
    skip,
}: {
    variables: ChannelSearchVariables;
    skip?: boolean;
}): ChannelSearchQueryResult => {
    const { loading, error, data } = useQuery<
        ChannelSearch,
        ChannelSearchVariables
    >(searchChannelQuery, {
        variables,
        skip,
    });

    return {
        loading,
        data: data && selectChannelSearchResults(data.channelCatalogSearchV2),
        error,
    };
};
