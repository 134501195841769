import React, { VFC } from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import cx from 'classnames';
import { ProductSearchResultItem } from 'src/apollo/selectors';
import { useEmployeeIdentity } from 'src/apollo/utils';
import GridTable from 'src/components/gridTable';
import CatalogLoadingIndicator from 'src/components/table/cells/loadingIndicators/catalogLoadingIndicator';
import EntityLoadingIndicator from 'src/components/table/cells/loadingIndicators/entityLoadingIndicator';
import PillLoadingIndicator from 'src/components/table/cells/loadingIndicators/pillLoadingIndicator';
import SingleLineLoadingIndicator from 'src/components/table/cells/loadingIndicators/singleLineLoadingIndicator';
import {
    LOADING_INDICATOR_RIGHT_ALIGNMENT_CLASSNAME,
    PAGINATED_LIMIT_OPTIONS,
    TABLE_RESULTS_COMMON_CLASSNAME,
} from 'src/pages/searchES/constants';
import { TableCommonProps } from 'src/pages/searchES/pages/table';

export const CLASSNAME = 'ProductResultsGridTable';
export const TEST_ID = CLASSNAME;

export interface Props extends TableCommonProps {
    data?: ProductSearchResultItem[];
    onSort: (name: string, direction: string) => void;
}

const defaultColumnDefs = {
    sortable: true,
};

const ProductResultsGridTable: VFC<Props> = ({
    loading,
    data,
    paginated,
    totalCount,
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
    onSort,
}) => {
    const { isEmployee } = useEmployeeIdentity();
    const isNoData = !loading && !data?.length;
    const handledData = loading ? [] : data;

    return (
        <GridTable
            name={CLASSNAME}
            className={cx(CLASSNAME, TABLE_RESULTS_COMMON_CLASSNAME)}
            testId={TEST_ID}
            data={handledData}
            loading={loading}
            sortBy={{ key: '', direction: 'asc' }}
            onSort={onSort}
            defaultColumnDefs={defaultColumnDefs}
            rowKey={({ upc, type }) => `${upc}-${type}`}
            totalCount={totalCount}
            page={page}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            paginated={paginated && !isNoData}
            pageSizeOptions={PAGINATED_LIMIT_OPTIONS}
            showUpdateIndicator
            stickyHeader
            exportable={false}
            customizable={false}
        >
            <GridTable.Column
                name="inCatalog"
                template="inCatalog"
                minWidth={'36px'}
                LoadingIndicator={CatalogLoadingIndicator}
            />
            <GridTable.Column
                name="productName"
                title={formatMessage('catalog.productName')}
                template="thumbnailProductLink"
                minWidth="549px"
                maxWidth="2fr"
                sortable={false}
                LoadingIndicator={() => (
                    <EntityLoadingIndicator withLabel={false} />
                )}
            />

            <GridTable.Column
                name="artists"
                template="participantLink"
                title={formatMessage('product.metadata.artist')}
                minWidth="180px"
                maxWidth="3fr"
                LoadingIndicator={SingleLineLoadingIndicator}
            />

            <GridTable.Column
                name="labelNames"
                title={formatMessage('account.account')}
                template="accountDetails"
                minWidth="140px"
                maxWidth="2fr"
                sortable={false}
                visibility={isEmployee ? 'visible' : 'hidden'}
                LoadingIndicator={PillLoadingIndicator}
            />

            <GridTable.Column
                name="upc"
                title={formatMessage('product.metadata.upc')}
                template="truncatedText"
                minWidth={'130px'}
                maxWidth="max-content"
                sortable={false}
                LoadingIndicator={SingleLineLoadingIndicator}
            />

            <GridTable.Column
                name="releaseDate"
                template="date"
                title={formatMessage('song.release')}
                minWidth={'115px'}
                maxWidth="max-content"
                LoadingIndicator={() => (
                    <SingleLineLoadingIndicator
                        className={LOADING_INDICATOR_RIGHT_ALIGNMENT_CLASSNAME}
                    />
                )}
            />
        </GridTable>
    );
};

export default ProductResultsGridTable;
